import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Step1 from './components/Step1';
import { getElementSvgFile } from 'src/utils';
import { Button } from 'antd';

export default function AddSocket({ handleSetFeature, infoGeneralTemplate }: { handleSetFeature: () => void; infoGeneralTemplate: any }) {
  const [socket, setSocket] = useState<any>();
  const [svgContent, setSvgContent] = useState<any>();

  useEffect(() => {
    if (socket?.file_path_url) {
      getElementSvgFile(socket?.file_path_url).then((res: any) => {
        setSvgContent({
          ...res,
        });
      });
    }
  }, [socket?.file_path_url]);

  return (
    <div className={styles.wrapRenderStep}>
      <Button
        className="button-form !w-auto mb-3"
        onClick={() => {
          handleSetFeature();
        }}
      >
        キャンセル
      </Button>

      <Step1
        socket={socket}
        setSocket={setSocket}
        svgContent={svgContent}
        handleSetFeature={handleSetFeature}
        setSvgContent={setSvgContent}
        infoGeneralTemplate={infoGeneralTemplate}
      />
    </div>
  );
}
