/* eslint-disable react/jsx-key */
import GenerateTinyMaskSvg from '../element/GenerateTinyMask';

const TinyMaskPath = ({ shapeData, hiddenIndex }: any) => {
  return (
    <mask id="tinymask">
      {shapeData?.map((shapeInfo: any, index: any) => {
        if (hiddenIndex === index || ![98, 99, 100].includes(shapeInfo?.type)) {
          <></>;
        } else {
          return <GenerateTinyMaskSvg shapeInfo={shapeInfo} />;
        }
      })}
    </mask>
  );
};

export default TinyMaskPath;
