import React from 'react';
import { generateTransformAttributes } from '../../utils';
import { PADDING_SELECT_SVG_OUTLINE } from 'src/assets/dataUI';

// type 8
const SvgContentList = ({
  shapeInfo,
  outline,
  drawOutline,
  handleClick,
  index,
  isSingleShowMode,
  isRestoreMode,
  isSocket,
  hiddenIndex,
  isDrawInEdit
}: any) => {
  const widthViewBox = Number(shapeInfo?.attributes?.width);
  const heightViewBox = Number(shapeInfo?.attributes?.height);
  const filterAttributes = (attributes: any) => {
    const filteredAttributes = { ...attributes };
    if (filteredAttributes.style) {
      delete filteredAttributes.style;
    }
    return filteredAttributes;
  };

  console.log({
    isSocket, index, hiddenIndex
  })

  const renderChild = (child: any, i: number) => {
    if (child.children.length > 0) {
      return (
        <>
          {child.children.map((nestedChild: any, z: number) =>
            renderChild(nestedChild, z)
          )}
        </>
      );
    } else {
      return (
        <child.tagName
          {...filterAttributes(child.attributes)}
          key={"childSvgContent" + i}
        >
          {child.content && child.content !== "\n" && child.content}
        </child.tagName>
      );
    }
  };

  return drawOutline ? (
    <g onClick={handleClick}>
      {shapeInfo?.children?.map((pathListItem: any) => {
        if (pathListItem.children.length > 0) {
          return (
            <pathListItem.tagName
              {...filterAttributes(pathListItem.attributes)}
              key={pathListItem.attributes.id}
              id={index}
              onClick={handleClick}
              fill= {isSocket ? ['socket'].includes(shapeInfo.cate) && pathListItem.tagName == 'path' ? 'none' : 'grey' : outline?.paddingColor ?? "white"}
              fillRule={shapeInfo?.fillRule}
              clipRule={shapeInfo?.clipRule}
              stroke={shapeInfo?.stroke ?? "black"}
              stroke-width={1 / (shapeInfo?.scale?.base || 1)}
              // stroke-width={isSocket ? ['socket'].includes(shapeInfo.cate) ? 1 : Number(shapeInfo?.outlinePadding ?? "0.5") : Number(shapeInfo?.outlinePadding ?? "0.5")}
              strokeLinecap="round"
              strokeLinejoin={shapeInfo?.strokeLinejoin ? "round" : ""}
              transform={generateTransformAttributes({
                shapeInfo,
                isApplyMatrix: true,
              })}
            >
              {pathListItem.children.map((child: any, index: any) =>
                renderChild(child, index)
              )}
            </pathListItem.tagName>
          );
        } else {
          return (
            <pathListItem.tagName
              {...filterAttributes(pathListItem.attributes)}
              key={pathListItem.attributes.id}
              id={index}
              onClick={handleClick}
              fill= {isSocket ? ['socket'].includes(shapeInfo.cate) && pathListItem.tagName == 'path' ? 'none' : 'grey' : outline?.paddingColor ?? "white"}
              fillRule={shapeInfo?.fillRule}
              clipRule={shapeInfo?.clipRule}
              stroke={shapeInfo?.stroke ?? "black"}
              stroke-width={(isSocket && isDrawInEdit ? 2.5 : 1) / (shapeInfo?.scale?.base || 1)}
              //stroke-width={isSocket ? ['socket'].includes(shapeInfo.cate) ? 1 : Number(shapeInfo?.outlinePadding ?? "0.5") : Number(shapeInfo?.outlinePadding ?? "0.5")}
              strokeLinecap="round"
              strokeLinejoin={shapeInfo?.strokeLinejoin ? "round" : ""}
              transform={generateTransformAttributes({
                shapeInfo,
                isApplyMatrix: true,
              })}
            >
              {pathListItem.content &&
                pathListItem.content !== "\n" &&
                pathListItem.content}
            </pathListItem.tagName>
          );
        }
      })}
    </g>
  ) : (
    <image
      crossOrigin={"anonymous"}
      onClick={handleClick}
      transform={
        generateTransformAttributes({
          shapeInfo,
          isApplyMatrix: true,
        }) +
        ` translate(${
          shapeInfo?.shapeSize?.x -
            shapeInfo?.outlinePadding * 0.5 -
            PADDING_SELECT_SVG_OUTLINE / shapeInfo?.scale?.base ?? 0
        }, ${
          shapeInfo?.shapeSize?.y -
            shapeInfo?.outlinePadding * 0.5 -
            PADDING_SELECT_SVG_OUTLINE / shapeInfo?.scale?.base ?? 0
        })`
      }
      width={
        shapeInfo?.shapeSize?.width +
        shapeInfo?.outlinePadding +
        (2 * PADDING_SELECT_SVG_OUTLINE) / shapeInfo?.scale?.base
      }
      height={
        shapeInfo?.shapeSize?.height +
        shapeInfo?.outlinePadding +
        (2 * PADDING_SELECT_SVG_OUTLINE) / shapeInfo?.scale?.base
      }
      preserveAspectRatio="none"
    />
  );
};

export default SvgContentList;
