const IconPreviewImage = ({ className }: any) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 50 50" fill="none">
    <circle cx="25" cy="25" r="4.75" stroke="#222222" strokeWidth="3" />
    <path
      d="M38.2877 24.3712C38.411 24.6492 38.4727 24.7882 38.4727 25C38.4727 25.2118 38.411 25.3508 38.2877 25.6288C37.3557 27.7299 33.7189 34.375 25.0007 34.375C16.2824 34.375 12.6456 27.7299 11.7136 25.6288C11.5903 25.3508 11.5287 25.2118 11.5287 25C11.5287 24.7882 11.5903 24.6492 11.7136 24.3712C12.6456 22.2701 16.2824 15.625 25.0007 15.625C33.7189 15.625 37.3557 22.2701 38.2877 24.3712Z"
      stroke="#222222"
      strokeWidth="3"
    />
    <path
      d="M36.459 7.29102H40.9923C42.7837 7.29102 43.6793 7.29102 44.2358 7.84751C44.7923 8.40401 44.7923 9.29968 44.7923 11.091V15.6243"
      stroke="#222222"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M36.459 42.709H40.9923C42.7837 42.709 43.6793 42.709 44.2358 42.1525C44.7923 41.596 44.7923 40.7003 44.7923 38.909V34.3757"
      stroke="#222222"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M13.541 7.29102H9.00768C7.21635 7.29102 6.32068 7.29102 5.76418 7.84751C5.20768 8.40401 5.20768 9.29968 5.20768 11.091V15.6243"
      stroke="#222222"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M13.541 42.709H9.00768C7.21635 42.709 6.32068 42.709 5.76418 42.1525C5.20768 41.596 5.20768 40.7003 5.20768 38.909V34.3757"
      stroke="#222222"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export default IconPreviewImage;
