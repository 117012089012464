import useRemoveImageBackground from 'src/hook/useRemoveBackground';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface CartContextType {
  acrylic: any;
  setAcrylic: (val: any) => void;
  autoRemoveBackground: (image: HTMLImageElement) => Promise<string | undefined>;
  modelLoaded: any;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [acrylic, setAcrylic] = useState<any>();
  const { autoRemoveBackground, modelLoaded } = useRemoveImageBackground();

  const value: CartContextType = {
    acrylic,
    setAcrylic,
    autoRemoveBackground,
    modelLoaded,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
