const IconSecend = ({ className }: any) => (
  <svg className={className} width={11} height={15} viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.833125 15V13.94C5.43313 9.92 7.53313 6.98 7.53313 4.48C7.53313 2.8 6.67313 1.56 4.85313 1.56C3.65313 1.56 2.61313 2.3 1.79313 3.26L0.733125 2.24C1.93313 0.94 3.21313 0.079999 5.07313 0.079999C7.69313 0.079999 9.33313 1.78 9.33313 4.4C9.33313 7.34 7.03313 10.32 3.57313 13.58C4.35313 13.5 5.23312 13.44 5.97313 13.44H10.0331V15H0.833125Z"
      fill="#c0c0c0"
    />
  </svg>
);

export default IconSecend;
