import React from 'react';
import { generatePointLine, calculateNewPoint } from '../utils';

const GenerateExternalMaskSvg = ({ shapeInfo, handleClick }: any) => {
  switch (shapeInfo?.type) {
    case 99:
      return (
        <>
          <circle
            onClick={handleClick}
            cx={shapeInfo.startX}
            cy={shapeInfo.startY}
            r={shapeInfo.smallRadius + shapeInfo.borderSmallWidth}
            stroke="black"
            strokeWidth={1}
            transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
            fill="none"
            // mask="url(#tinymask)"
          />
        </>
      );
    case 100:
      return (
        <>
          <rect
            id="1"
            onClick={handleClick}
            width={shapeInfo?.width}
            height={shapeInfo?.height}
            x={shapeInfo?.x}
            y={shapeInfo?.y}
            transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
            stroke="black"
            strokeWidth={1}
            fill="none"
            // mask="url(#tinymask)"
          />
        </>
      );
    case 98:
      return (
        <>
          <polygon
            points={generatePointLine(calculateNewPoint(shapeInfo?.points, shapeInfo?.scale3))}
            transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
            stroke="none"
            strokeWidth={1}
            mask="url(#tinymask)"
          />
        </>
      );
    default:
      return <></>;
  }
};

export default GenerateExternalMaskSvg;
