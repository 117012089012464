import { TypeFilterRangeTime } from './enums';

export const pageSizeList = [
  {
    value: 10,
    label: '10 件表示',
  },
  {
    value: 15,
    label: '15 件表示',
  },
  {
    value: 20,
    label: '20 件表示',
  },
  {
    value: 25,
    label: '25 件表示',
  },
];

// 1.Tiếp nhận đơn hàng - 注文受付
// 2.Xử lý đơn hàng - 処理中
// 3.Giao hàng thành công - 配送済
// 4. Cancel - キャンセル
export const ORDER_STATUS_ACCEPTED = 1;
export const ORDER_STATUS_PROCESSING = 2;
export const ORDER_STATUS_SUCCESS = 3;
export const ORDER_STATUS_CANCEL = 4;

export const ORDER_STATUS = [
  {
    value: 1,
    label: '注文受付',
    title: '',
  },
  {
    value: 2,
    label: '処理中',
    title: '',
  },
  {
    value: 3,
    label: '配送済',
    title: '',
  },
  {
    value: 4,
    label: 'キャンセル',
    title: '',
  },
];

export const ROLE = [
  {
    value: 0,
    label: 'スタッフ',
    title: '',
  },
  {
    value: 1,
    label: '管理者',
    title: '',
  },
];

export const ROLE_ADMIN = 1;
export const ROLE_STAFF = 0;

// 1. 表示: Hiển thị notify
// 0. 非表示: Không hiển thị notify
export const NOTIFICATION_STATUS = [
  {
    value: 0,
    label: '非表示',
    title: '',
  },
  {
    value: 1,
    label: '表示',
    title: '',
  },
];

export const NOTIFY_STATUS_SHOW = 1;
export const NOTIFY_STATUS_HIDE = 0;

export const HIGHLIGHT_STATUS = [
  {
    value: 0,
    label: 'なし',
    title: '',
  },
  {
    value: 1,
    label: 'あり',
    title: '',
  },
];

export const HIGHLIGHT_SHOW = 1;
export const HIGHLIGHT_HIDE = 0;

export const labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export const rangeTimeFitler = [
  { value: TypeFilterRangeTime.THIS_MONTH, label: '今月' },
  { value: TypeFilterRangeTime.LAST_MONTH, label: '先月' },
  { value: TypeFilterRangeTime.LAST_TWO_MONTH, label: '先々月' },
];

export const VAT_PAYMENT_CART = 0.1;

export const STATUS_CODE_NOT_PERMISS_DELETE_ACRYLIC = 403;

export const ID_NOTIFY_BOOKMARK = '1';
