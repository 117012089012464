import React from 'react';
import { generatePointLine, calculateNewPoint } from '../utils';

const GenerateSmallMaskSvg = ({ shapeInfo }: any) => {
  switch (shapeInfo?.type) {
    case 99:
      return (
        <>
          <circle
            cx={shapeInfo.startX}
            cy={shapeInfo.startY}
            r={shapeInfo.smallRadius + shapeInfo.borderSmallWidth + shapeInfo.paddingSmallCircle}
            stroke="black"
            transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
          />
        </>
      );
    case 100:
      return (
        <>
          <rect
            // id="3"
            width={shapeInfo?.width + 6}
            height={shapeInfo?.height + 6}
            x={shapeInfo?.x - 3}
            y={shapeInfo?.y - 3}
            transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
            stroke="black"
          />
        </>
      );
    case 98:
      return (
        <>
          <polygon
            points={generatePointLine(calculateNewPoint(shapeInfo?.points, shapeInfo?.scale2))}
            transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
            stroke="black"
          />
        </>
      );
    default:
      return <></>;
  }
};

export default GenerateSmallMaskSvg;
