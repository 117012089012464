import { useQuery } from 'react-query';
import { api } from 'src/api/axios';

export const useDataChartRevenue = (year: any) =>
  useQuery(['chartRevenue', year], async () => {
    const res = await api.get('/api/cms/chart-data', {
      params: {
        year: year,
      },
    });
    return res.data.data;
  });
