import React from 'react';
import GenerateShapeSvgByType from '../element/GenerateShapeSvg';
import { endDrag, setOffset, setTransform, makeDraggable } from '../controllers/TouchController';

const BaseSvgPath = ({
  shapeData,
  outline,
  handleClick,
  hiddenIndex,
  allowClickSvG,
  onDragEnd,
  onRotateEnd,
  extraState,
  isSingleShowMode,
  isRestoreMode,
  whiteBackground,
}: any) => {
  return (
    <g>
      {shapeData?.map((shapeInfo: any, index: any) => {
        if (hiddenIndex === index) {
          return <></>;
        } else {
          return (
            <GenerateShapeSvgByType
              isSingleShowMode={isSingleShowMode}
              key={index}
              shapeInfo={shapeInfo}
              outline={outline}
              drawOutline={false}
              handleClick={(e: any) => {
                if (allowClickSvG) {
                  // console.log({ hiddenIndex });
                  if (hiddenIndex > -1) {
                    endDrag({
                      evt: e,
                      onDragEnd,
                      onRotateEnd,
                      index: hiddenIndex,
                      extraState,
                    });
                    handleClick(-1);
                  } else {
                    if (extraState?.currentEdittingIndex === -1) {
                      extraState.currentEdittingIndex = index;
                      handleClick(index);
                      setOffset({
                        x: 0,
                        y: 0,
                      });
                      extraState.offsetEditing = {
                        x: 0,
                        y: 0,
                      };
                      setTransform(0);
                      extraState.transformEditing = 0;
                      makeDraggable(index, extraState);
                    }
                  }
                }
              }}
              index={index}
              isRestoreMode={isRestoreMode}
              whiteBackground={whiteBackground}
            />
          );
        }
      })}
    </g>
  );
};

export default BaseSvgPath;
