import { useQuery } from 'react-query';
import { api } from 'src/api/axios';

export const useDetailAcrylicPlate = (id: number) =>
  useQuery(
    ['detailAcrylicPlate', id],
    async () => {
      if (id) {
        const res = await api.get(`/api/cms/acrylic-plate/${id}`);
        return res.data.data;
      }
      return;
    },
    {
      retry: 0,
    }
  );
