const IconUpload = ({ className }: any) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={48} height={32} viewBox="0 0 48 32" fill="none">
    <path
      d="M36.6625 8.6626C35.4283 6.36023 33.6357 4.4032 31.4348 2.96545C28.9028 1.31165 25.96 0.4375 22.9237 0.4375C15.3757 0.4375 8.97913 5.8175 7.6106 13.1238C3.26111 13.9302 0 17.7523 0 22.2845C0 27.4229 4.1803 31.6031 9.3186 31.6031H22.5938V19.8071L19.6143 22.7866C19.0649 23.3356 18.1747 23.3356 17.6254 22.7866C17.076 22.2373 17.076 21.347 17.6254 20.7977L23.0057 15.4177C23.5547 14.8684 24.4449 14.8684 24.9943 15.4177L30.3746 20.7977C30.924 21.347 30.924 22.2373 30.3746 22.7866C30.1 23.0613 29.74 23.1986 29.3804 23.1986C29.0204 23.1986 28.6604 23.0613 28.3857 22.7866L25.4062 19.8071V31.6031H36.5292C42.8544 31.6031 48 26.4575 48 20.1323C48 13.8518 42.9265 8.73401 36.6625 8.6626Z"
      fill="#C0C0C0"
    />
  </svg>
);

export default IconUpload;
