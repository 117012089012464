import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDetailNotify } from 'src/api/useDetailNotify';
import IconArrowLeft from 'src/assets/icon/IconArrowLeft';
import Loading from 'src/components/common/loading';
import 'src/components/features/superAdmin/detailContent.modules.scss';
import { Button, message } from 'antd';
import IconTrash from 'src/assets/icon/IconTrash';
import ModalConfirm from 'src/components/common/modal/modalConfirm';
import { useDeleteNotify } from 'src/api/useDeleteNotify';
import { useMutation } from 'react-query';
import IconEdit from 'src/assets/icon/IconEdit';
import { ID_NOTIFY_BOOKMARK, NOTIFY_STATUS_SHOW } from 'src/assets/constants';
import moment from 'moment';
import IconBack from 'src/assets/icon/IconBack';
import NotFoundPage from 'src/pages/notFoundPage';
import ModalPreviewNotify from 'src/components/common/modal/modalPreviewNotify';
import IconPhone from 'src/assets/images/phone.png';
import { getClassStatusNotify, handleGetStatusNotify } from 'src/utils';

const NotificationDetailComponent = () => {
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data: detailData, isLoading: isLoadingDetail, refetch, isError } = useDetailNotify(Number(id));
  const { isLoading: isLoadingDelete, mutate: onDelete } = useMutation((id: number | string) => useDeleteNotify(id), {
    onSuccess: async () => {
      refetch();
      message.success('お知らせ削除は成功しました');
      setIsOpenModalDelete(false);
      history('/notification');
    },
    onError: () => {
      message.error('システムにエラーが発生しました。後でもう一度お試しください');
    },
  });
  const [dataNotifyDetail, setDataNotifyDetail] = useState<any>({});
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [isOpenModalPreview, setIsOpenModalPreview] = useState<boolean>(false);

  const handleGoBack = () => {
    history('/notification');
  };

  useEffect(() => {
    if (!detailData) return;
    setDataNotifyDetail(detailData);
  }, [detailData]);

  const handleConfirmDelete = () => {
    if (id) {
      onDelete(id);
    }
  };

  const handleCloseModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const handleOpenModalDelete = () => {
    setIsOpenModalDelete(true);
  };

  const handleGoToEditNotify = () => {
    history(`/notification/edit/${id}`);
  };

  const handleOpenModalPreview = () => {
    setIsOpenModalPreview(true);
  };

  const handleCloseModalPreview = () => {
    setIsOpenModalPreview(false);
  };

  if (!detailData || Object.keys(detailData).length === 0) {
    if (isError) {
      return <NotFoundPage />;
    }
    return null;
  }

  return (
    <>
      {isLoadingDetail ? (
        <Loading />
      ) : (
        <div className="page-content company-page">
          <div className="page-content-title border-bottom-title flex items-center justify-between">
            <div className="inline-flex items-center cursor-pointer" onClick={handleGoBack}>
              <div className="mr-[17px]">
                <IconArrowLeft />
              </div>
              <span className="line-1">お知らせの詳細</span>
            </div>
          </div>

          <div className="px-[32px] detail-notification">
            <div className="flex items-center justify-end px-[12px] py-[8px]">
              <span className={`notify-request-status inline-block ${getClassStatusNotify(dataNotifyDetail?.status)}`}>
                {handleGetStatusNotify(dataNotifyDetail?.status)}
              </span>

              <div className="flex gap-[20px] ml-auto">
                <Button className="button-preview h-[48px] w-[135px]" onClick={handleOpenModalPreview}>
                  <img src={IconPhone} alt="icon-phone" className="cursor-pointer" />
                  モバイルで見る
                </Button>
                <Button className="button-add h-[48px] w-[115px]" onClick={handleGoToEditNotify}>
                  <IconEdit className="mr-[10px]" />
                  編集
                </Button>
                {id !== ID_NOTIFY_BOOKMARK && (
                  <Button
                    disabled={Number(dataNotifyDetail.status) === NOTIFY_STATUS_SHOW}
                    className="button-remove w-[115px] h-[48px]"
                    onClick={handleOpenModalDelete}
                  >
                    <IconTrash className="mr-[10px]" /> 削除
                  </Button>
                )}
              </div>
            </div>

            <h1 className="text-[32px] font-bold mb-[10px]">{dataNotifyDetail?.title}</h1>
            <div className="detail-content-date mb-[40px]">
              作成日時 : {moment(dataNotifyDetail?.created_at).utc().format('YYYY/MM/DD')}
            </div>

            <div
              className="detail-content-description"
              dangerouslySetInnerHTML={{ __html: dataNotifyDetail?.content }}
            ></div>
            <div className="flex justify-center mt-auto">
              <Button className="detail-content-back h-[46px] w-[190px]" onClick={handleGoBack}>
                お知らせ一覧に戻る
                <IconBack className="detail-back-icon" />
              </Button>
            </div>
          </div>
        </div>
      )}
      <ModalConfirm
        isShowModal={isOpenModalDelete}
        onCancel={handleCloseModalDelete}
        onOk={handleConfirmDelete}
        content="削除確認"
        subContent="選択したお知らせを削除してもよろしいですか？"
        loadingButtonOk={isLoadingDelete}
        isClose={false}
        isIcon={false}
        textOk="削除"
      />
      <ModalPreviewNotify
        isOpenModalPreview={isOpenModalPreview}
        handleCloseModalPreview={handleCloseModalPreview}
        title={dataNotifyDetail?.title}
        created_at={dataNotifyDetail?.created_at}
        content={dataNotifyDetail.content}
      />
    </>
  );
};

export default NotificationDetailComponent;
