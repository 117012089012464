const IconThird = ({ className }: any) => (
  <svg className={className} width={12} height={16} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.21313 15.28C2.91313 15.28 1.49313 14.32 0.533125 13.26L1.45313 12.06C2.31313 12.96 3.41313 13.76 5.09312 13.76C6.83313 13.76 8.07313 12.7 8.07313 11.02C8.07313 9.26 6.89313 8.1 3.51313 8.1V6.68C6.53313 6.68 7.55313 5.48 7.55313 3.88C7.55313 2.48 6.61313 1.56 5.09312 1.56C3.91313 1.56 2.87313 2.16 2.05313 3L1.07313 1.82C2.17313 0.819999 3.47313 0.079999 5.15313 0.079999C7.61313 0.079999 9.41313 1.42 9.41313 3.76C9.41313 5.54 8.37313 6.72 6.81313 7.28V7.36C8.53313 7.78 9.93313 9.08 9.93313 11.1C9.93313 13.7 7.83313 15.28 5.21313 15.28Z"
      fill="#c0c0c0"
    />
  </svg>
);

export default IconThird;
