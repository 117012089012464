import { useQuery } from 'react-query';
import { api } from 'src/api/axios';
import { clearToken } from 'src/utils/cookie';

export const useUserInFo = () =>
  useQuery(
    ['userInfo'],
    async () => {
      const res = await api.get(`/api/cms/user-info`);
      return res.data.data;
    },
    {
      onError: (error: any) => {
        if (error.response.status === 401) {
          clearToken();
          localStorage.clear();
          window.location.replace('/login');
        }
      },
    }
  );
