import { apiFormData } from 'src/api/axios';

export interface TemplateType {
  name: string;
  acrylic_plate_size_id: number;
  shipping_fee: number | string;
  price: number | string;
  thumbnail?: any;
  image?: any;
  background?: any;
  editor_content?: string;
}

export const useAddTemplate = (params: TemplateType) => apiFormData.post(`/api/cms/template-design`, params);
