import React from "react";
import styles from "./index.module.scss";
import IconEditorDuplicate from "src/assets/icon/IconEditorDuplicate";
import IconEditorSwitch from "src/assets/icon/IconEditorSwitch";
import IconEditorUp from "src/assets/icon/IconEditorUp";
import IconEditorDown from "src/assets/icon/IconEditorDown";
import IconEditItem from "src/assets/icon/IconEditItem";


export const workMode = [
  {
    id: "duplicate",
    name: "複製",
    desc: "複製",
    minWidth: "28px",
    icon: <IconEditorDuplicate />,
  },
  {
    id: "flip-horizontally",
    name: "左右反転",
    desc: "左右反転",
    minWidth: "42px",
    icon: <IconEditorSwitch />,
  },
  {
    id: "layer-up",
    name: "重ね順を上へ",
    desc: "重ね順を上へ",
    minWidth: "68px",
    icon: <IconEditorUp />,
  },
  {
    id: "layer-down",
    name: "重ね順を下へ",
    desc: "重ね順を下へ",
    minWidth: "68px",
    icon: <IconEditorDown />,
  },
  {
    id: "edit",
    name: "編集",
    desc: "編集",
    minWidth: "20px",
    icon: <IconEditItem />,
  },
];

const NavBarEditor: React.FC<any> = ({ getClickCallback, ratioSize }) => {
  return (
    <div
      className={`${styles.navBarEditor} d-flex items-center justify-between`}
    >
      <div className={styles.navBarContent}>
        {workMode.map((data) => (
          <div
            key={`navbar-${data.id}`}
            data-navbar-id={data.id}
            className={styles.navBarContentItem}
            style={{ minWidth: data.minWidth }}
            onClick={getClickCallback(data.id, ratioSize)}
          >
            <div className={styles.icon}>{data.icon}</div>
            <span className={styles.name}>{data.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavBarEditor;
