import 'moment/locale/ja';
import { Button, Input, Pagination, Select, Spin, Table } from 'antd';
import { useMemo, useState } from 'react';
import searchIcon from 'src/assets/images/search-icon.png';
import { useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { pageSizeList } from 'src/assets/constants';
import TableNoteNoData from 'src/components/common/tableNoteNoData';
import IconAdd from 'src/assets/icon/IconAdd';
import moment from 'moment';
import { useListPart } from 'src/api/useListPart';
import { getIndexTable } from 'src/utils';

interface DataType {
  key: string;
  name: string;
  location: string;
  updated_at: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: '番号',
    key: 'index',
    dataIndex: 'index',
    width: 228,
  },
  {
    title: 'サブカテゴリー名',
    key: 'name',
    dataIndex: 'name',
    width: 300,
  },
  {
    title: 'カテゴリー名',
    dataIndex: 'category',
    key: 'category',
    width: 300,
  },
  {
    title: '作成日時',
    key: 'created_at',
    dataIndex: 'created_at',
    width: 310,
  },
  {
    title: '登録数',
    key: 'parts_count',
    dataIndex: 'parts_count',
    width: 270,
  },
];

const PartsListComponent = () => {
  const history = useNavigate();
  const [keyword, setKeyword] = useState<string>('');
  const [keywordSearch, setKeywordSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [sizePage, setSizePage] = useState<number>(10);

  const { data: partListData, isLoading } = useListPart(keywordSearch, page, sizePage);

  const dataSource = useMemo(() => {
    if (partListData) {
      return partListData?.records?.data?.map((part: any, index: any) => ({
        ...part,
        key: part?.id,
        index: <span className="line-1">{getIndexTable(page, sizePage, index)}</span>,
        name: (
          <span
            onClick={() => handleGoToPartDetail(part?.id)}
            className="line-1 break-all column-redirect cursor-pointer"
            style={{ display: '-webkit-inline-box' }}
          >
            {part?.name}
          </span>
        ),
        category: <span className="line-1 break-all">{part?.category?.name || ''}</span>,
        created_at: (
          <span className="line-1 break-all">{moment(part?.created_at)?.utc()?.format('YYYY/MM/DD') || ''}</span>
        ),
        parts_count: <span className="line-1">{part?.parts_count}</span>,
      }));
    }
    return [];
  }, [partListData]);

  const handleChangeKeyword = (value: string) => {
    setKeyword(value);
  };

  const iconSearch = () => {
    return (
      <div className="p-[5px] cursor-pointer" onClick={handleSearch}>
        <img src={searchIcon} alt="" />
      </div>
    );
  };

  const handleChangeSizePage = (value: number) => {
    setPage(1);
    setSizePage(value);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleSearch = () => {
    setPage(1);
    setKeywordSearch(keyword?.trim());
    setKeyword(keyword?.trim());
  };

  const handleGoToCreatePage = () => {
    history('/product/parts/create');
  };

  const handleGoToPartDetail = (id: number | undefined) => {
    history(`/product/parts/${id}`);
  };

  return (
    <div className="page-content company-page">
      <div className="mb-[16px]">
        <h2 className="page-content-title border-bottom-title">パーツ一覧</h2>
      </div>
      <div className="page-content-table">
        <div className="flex justify-between items-center p-[16px]">
          <div className="flex items-center">
            <Input
              onPressEnter={handleSearch}
              className="input-search mr-[8px] min-w-[423px]"
              placeholder="サブカテゴリー名を検索してください"
              suffix={iconSearch()}
              onChange={(e) => handleChangeKeyword(e.target.value)}
              value={keyword}
            />
          </div>
          <Button className="button-add max-h-[40px] w-[128px]" onClick={handleGoToCreatePage}>
            <IconAdd className="mr-[10px]" />
            追加
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={isLoading}
          locale={{
            emptyText: <TableNoteNoData />,
          }}
        />

        <div className="py-[20px] px-[12px] flex justify-between items-center">
          <div className="pagination-info">
            {partListData?.limitFrom} - {partListData?.limitTo} 件表示/全 {partListData?.totalRow} 件中
          </div>
          <div className="flex">
            <Pagination
              current={page}
              total={partListData?.totalRow}
              className="pagination-main mr-[20px]"
              showSizeChanger={false}
              pageSize={sizePage}
              onChange={(page) => handleChangePage(page)}
            />
            <Select
              defaultValue={10}
              style={{ width: 153 }}
              className="pagination-options"
              onChange={(e) => handleChangeSizePage(e)}
              options={pageSizeList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartsListComponent;
