const IconOrdersStatistic = ({ className }: any) => (
  <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.62467 12.6484L11.5413 15.5651L18.1038 9.0026M24.6663 27.9609V8.71094C24.6663 6.26071 24.6663 5.03559 24.1895 4.09973C23.77 3.27652 23.1008 2.60723 22.2776 2.18778C21.3417 1.71094 20.1166 1.71094 17.6663 1.71094H8.33301C5.88278 1.71094 4.65766 1.71094 3.7218 2.18778C2.89859 2.60723 2.2293 3.27652 1.80985 4.09973C1.33301 5.03559 1.33301 6.26071 1.33301 8.71094V27.9609L5.34342 25.0443L8.98926 27.9609L12.9997 25.0443L17.0101 27.9609L20.6559 25.0443L24.6663 27.9609Z"
      stroke="#1C48E7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconOrdersStatistic;
