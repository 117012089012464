const IconImageNoBG = ({ className }: any) => (
  <svg className={className} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8333 2.66667L13.5 4.33333L16.8333 1M8.91667 1H5C3.59987 1 2.8998 1 2.36502 1.27248C1.89462 1.51217 1.51217 1.89462 1.27248 2.36502C1 2.8998 1 3.59987 1 5V12C1 13.4001 1 14.1002 1.27248 14.635C1.51217 15.1054 1.89462 15.4878 2.36502 15.7275C2.8998 16 3.59987 16 5 16H12.6667C13.4416 16 13.8291 16 14.147 15.9148C15.0098 15.6836 15.6836 15.0098 15.9148 14.147C16 13.8291 16 13.4416 16 12.6667M7.25 5.58333C7.25 6.50381 6.50381 7.25 5.58333 7.25C4.66286 7.25 3.91667 6.50381 3.91667 5.58333C3.91667 4.66286 4.66286 3.91667 5.58333 3.91667C6.50381 3.91667 7.25 4.66286 7.25 5.58333ZM10.9917 8.43179L3.94262 14.84C3.54614 15.2005 3.34789 15.3807 3.33036 15.5368C3.31516 15.6722 3.36704 15.8064 3.46932 15.8963C3.58732 16 3.85523 16 4.39107 16H12.2133C13.4126 16 14.0123 16 14.4833 15.7985C15.0745 15.5456 15.5456 15.0745 15.7985 14.4833C16 14.0123 16 13.4126 16 12.2133C16 11.8098 16 11.608 15.9559 11.4201C15.9005 11.184 15.7941 10.9628 15.6444 10.772C15.5252 10.6202 15.3677 10.4941 15.0526 10.242L12.7215 8.37722C12.4062 8.12492 12.2485 7.99878 12.0748 7.95426C11.9218 7.91502 11.7607 7.9201 11.6104 7.96891C11.44 8.02428 11.2905 8.16012 10.9917 8.43179Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconImageNoBG;
