const IconImageHaveBG = ({ className }: any) => (
  <svg className={className} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1046 1L15.6053 4.33333L13.9355 2.74337M16 1L13.9355 2.74337M8.5 1H4.78947C3.46303 1 2.79981 1 2.29318 1.27248C1.84753 1.51217 1.48521 1.89462 1.25814 2.36502C1 2.8998 1 3.59987 1 5V12C1 13.4001 1 14.1002 1.25814 14.635C1.48521 15.1054 1.84753 15.4878 2.29318 15.7275C2.79981 16 3.46303 16 4.78947 16H12.0526C12.7868 16 13.1539 16 13.4551 15.9148C14.2724 15.6836 14.9108 15.0098 15.1298 14.147C15.2105 13.8291 15.2105 13.4416 15.2105 12.6667M12.0526 4.33333L13.9355 2.74337M6.92105 5.58333C6.92105 6.50381 6.21413 7.25 5.34211 7.25C4.47008 7.25 3.76316 6.50381 3.76316 5.58333C3.76316 4.66286 4.47008 3.91667 5.34211 3.91667C6.21413 3.91667 6.92105 4.66286 6.92105 5.58333ZM10.4658 8.43179L3.78775 14.84C3.41213 15.2005 3.22432 15.3807 3.20771 15.5368C3.19331 15.6722 3.24246 15.8064 3.33936 15.8963C3.45114 16 3.70496 16 4.21259 16H11.6231C12.7593 16 13.3274 16 13.7736 15.7985C14.3338 15.5456 14.78 15.0745 15.0197 14.4833C15.2105 14.0123 15.2105 13.4126 15.2105 12.2133C15.2105 11.8098 15.2105 11.608 15.1687 11.4201C15.1162 11.184 15.0155 10.9628 14.8736 10.772C14.7607 10.6202 14.6115 10.4941 14.313 10.242L12.1046 8.37722C11.8058 8.12492 11.6564 7.99878 11.4919 7.95426C11.3469 7.91502 11.1944 7.9201 11.052 7.96891C10.8905 8.02428 10.7489 8.16012 10.4658 8.43179Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconImageHaveBG;
