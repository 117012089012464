import { useQuery } from 'react-query';
import { api } from 'src/api/axios';

export const useListTemplate = (keyword: string, page: number, size: number) =>
  useQuery(['listTemplate', keyword, page, size], async () => {
    const res = await api.get('/api/cms/template-design', {
      params: {
        keyword: keyword,
        page: page,
        size: size,
      },
    });
    return res.data.data;
  });
