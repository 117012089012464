import { Button, Spin, Input, Pagination, Select, Table } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import searchIcon from 'src/assets/images/search-icon.png';
import { pageSizeList } from 'src/assets/constants';
import iconSelect from 'src/assets/images/arrow-down-2.png';
import TableNoteNoData from 'src/components/common/tableNoteNoData';
import type { ColumnsType } from 'antd/es/table';
import { getClassStatusOrder, handleGetStatusOrder } from 'src/utils';
import IconArrowLeft from 'src/assets/icon/IconArrowLeft';
import { useDetailUser } from 'src/api/useDetailUser';
import 'src/components/features/superAdmin/form.modules.scss';
import { useListOrder } from 'src/api/useListOrder';
import { useListOrderStatus } from 'src/api/useListOrderStatus';
import moment from 'moment';
import { numberWithCommas } from 'src/utils/price';
import { formatZipCode } from 'src/utils/formatZipCode';
import IconEdit from 'src/assets/icon/IconEdit';
import NotFoundPage from 'src/pages/notFoundPage';

interface DataType {
  key: string;
  name: string;
  location: string;
  updated_at: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: '注文番号',
    key: 'order_code',
    dataIndex: 'order_code',
    width: 400,
  },
  {
    title: '注文日時',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 250,
  },
  {
    title: '合計',
    dataIndex: 'total_price',
    key: 'total_price',
    width: 150,
    align: 'right',
  },
  {
    title: 'ステータス',
    key: 'status',
    dataIndex: 'status',
    width: 350,
    align: 'center',
  },
];

const detailUserInfo = [
  {
    label: 'ユーザー名',
    name: 'name',
    id: 1,
  },
  {
    label: '電話番号',
    name: 'phone',
    id: 2,
  },
  {
    label: '氏名',
    name: 'full_name',
    id: 3,
  },
  {
    label: '登録日時',
    name: 'created_at',
    id: 4,
  },
  {
    label: '住所',
    name: 'address',
    id: 5,
  },
];
const UserDetailComponent = () => {
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [orderId, setOrderId] = useState<string>('');
  const [orderIdSearch, setOrderIdSearch] = useState<string>('');
  const [status, setStatus] = useState<number | undefined>();
  const [page, setPage] = useState<number>(1);
  const [sizePage, setSizePage] = useState<number>(10);
  const [listStatusData, setListStatusData] = useState([]);
  const { data: detailData, isError } = useDetailUser(Number(id));
  const { data: listOrder, isLoading: isLoadingListOrder } = useListOrder({
    user_id: String(id),
    page,
    size: sizePage,
    status,
    order_code: orderIdSearch,
  });
  const { data: listStatus } = useListOrderStatus();

  const handleGoBack = () => {
    history('/user');
  };

  const handleGoOrderDetail = (id: number | undefined) => {
    history(`/order/${id}`);
  };

  const dataSource = useMemo(() => {
    if (listOrder) {
      return listOrder?.records?.data?.map((order: any) => ({
        ...order,
        key: order?.id,
        order_code: (
          <span onClick={() => handleGoOrderDetail(order?.id)} className="cursor-pointer column-redirect">
            {order?.order_code}
          </span>
        ),
        created_at: (
          <span className="site-name line-1 break-all">
            {order?.created_at ? moment(order?.created_at).utc().format('YYYY/MM/DD') : ''}
          </span>
        ),
        total_price: <span className="line-1 break-all">{numberWithCommas(order?.total_price)} 円</span>,
        status: (
          <span className={`company-request-status inline-block ${getClassStatusOrder(order?.status)}`}>
            {handleGetStatusOrder(order?.status)}
          </span>
        ),
      }));
    }
    return [];
  }, [listOrder]);

  const iconSearch = () => {
    return (
      <div className="p-[5px] cursor-pointer" onClick={handleSearch}>
        <img src={searchIcon} alt="icon-search" />
      </div>
    );
  };

  const handleSearch = () => {
    setPage(1);
    setOrderIdSearch(orderId?.trim());
    setOrderId(orderId?.trim());
  };

  const handleChangeKeyword = (value: string) => {
    setOrderId(value);
  };

  const handleSearchStatus = (status: number) => {
    setPage(1);
    setStatus(status);
    setOrderIdSearch(orderId?.trim());
    setOrderId(orderId?.trim());
  };

  const renderSelectIcon = () => {
    return <img src={iconSelect} alt="icon-select" />;
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeSizePage = (value: number) => {
    setPage(1);
    setSizePage(value);
  };

  const handleGoToEdit = () => {
    history(`/user/edit/${id}`);
  };

  useEffect(() => {
    if (!(listStatus && listStatus.length)) return;
    setListStatusData(listStatus);
  }, [listStatus]);

  const renderInfoUser = (info: string) => {
    if (info === 'created_at') {
      return moment(detailData?.[info]).utc().format('YYYY/MM/DD');
    }
    if (info === 'address') {
      return detailData?.post_code
        ? `〒${formatZipCode(detailData?.post_code)} ${detailData?.[info] ? detailData?.[info] : ''}`
        : `${detailData?.[info] ? detailData?.[info] : ''}`;
    }
    if (info === 'phone') {
      return detailData?.phone_1 ? `${detailData?.phone_1}-${detailData?.phone_2}-${detailData?.phone_3}` : '';
    }
    return detailData?.[info];
  };

  if (!detailData || Object.keys(detailData).length === 0) {
    if (isError) {
      return <NotFoundPage />;
    }
    return null;
  }

  return (
    <div className="page-content company-page">
      <div className="page-content-title border-bottom-title mb-[30px] flex items-center justify-between">
        <div className="inline-flex items-center cursor-pointer" onClick={handleGoBack}>
          <div className="mr-[17px]">
            <IconArrowLeft />
          </div>
          <span className="line-1">{detailData?.name}</span>
        </div>
        <Button className="button-add min-w-[155px] min-h-[48px]" onClick={handleGoToEdit}>
          <IconEdit className="mr-[10px]" />
          基本情報の編集
        </Button>
      </div>
      <div className="license-detail mb-[24px]">
        <div className="license-detail-title">基本情報</div>
        <div className="license-detail-content">
          {detailUserInfo.map((item: any) => (
            <div className="mb-[8px] flex w-6/12" key={item.id}>
              <label className="labe min-w-[277px]">{item.label}</label>
              <div className="text-[16px] text-[#000000] break-all w-detail-content px-[10px]">
                {renderInfoUser(item.name)}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="page-content-table">
        <div className="flex justify-between items-center p-[16px]">
          <div className="flex items-center">
            <Input
              onPressEnter={handleSearch}
              className="input-search mr-[8px] min-w-[360px]"
              placeholder="注文番号"
              suffix={iconSearch()}
              onChange={(e) => handleChangeKeyword(e.target.value)}
              value={orderId}
            />
            <Select
              options={listStatusData}
              placeholder="ステータス"
              className="company-select h-[40px] w-[200px]"
              allowClear={true}
              onChange={(value: number) => handleSearchStatus(value)}
              suffixIcon={renderSelectIcon()}
            />
          </div>
        </div>
        <>
          {isLoadingListOrder ? (
            <Spin className="position-center" />
          ) : (
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              loading={isLoadingListOrder}
              locale={{
                emptyText: <TableNoteNoData />,
              }}
            />
          )}
        </>
        <div className="py-[20px] px-[12px] flex justify-between items-center">
          <div className="pagination-info">
            {listOrder?.limitFrom} - {listOrder?.limitTo} 件表示/全 {listOrder?.totalRow} 件中
          </div>
          <div className="flex">
            <Pagination
              current={page}
              total={listOrder?.totalRow}
              className="pagination-main mr-[20px]"
              showSizeChanger={false}
              pageSize={sizePage}
              onChange={(page) => handleChangePage(page)}
            />
            <Select
              defaultValue={10}
              style={{ width: 153 }}
              className="pagination-options"
              onChange={(e) => handleChangeSizePage(e)}
              options={pageSizeList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailComponent;
