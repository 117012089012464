const IconWhiteDownload = ({ className }: any) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={26} height={25} viewBox="0 0 26 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0483 17.9703C12.9536 18.0095 12.8521 18.0296 12.7495 18.0295C12.6471 18.0297 12.5449 18.0095 12.4502 17.9703C12.3555 17.9311 12.2692 17.8732 12.1969 17.8006L8.03022 13.634L8.02786 13.6316C7.9538 13.5594 7.89482 13.4733 7.85434 13.3781C7.81385 13.2829 7.79266 13.1806 7.79201 13.0772C7.79135 12.9738 7.81124 12.8713 7.85052 12.7756C7.88979 12.6799 7.94768 12.593 8.02081 12.5198C8.09395 12.4467 8.18088 12.3888 8.27656 12.3495C8.37224 12.3103 8.47477 12.2904 8.57819 12.291C8.68162 12.2917 8.78389 12.3129 8.87906 12.3534C8.97424 12.3938 9.06041 12.4528 9.13261 12.5269L12.7492 16.1436L16.3636 12.5292L16.3659 12.5269C16.4381 12.4528 16.5243 12.3938 16.6194 12.3534C16.7146 12.3129 16.8169 12.2917 16.9203 12.291C17.0237 12.2904 17.1262 12.3103 17.2219 12.3495C17.3176 12.3888 17.4045 12.4467 17.4777 12.5198C17.5508 12.593 17.6087 12.6799 17.648 12.7756C17.6872 12.8713 17.7071 12.9738 17.7065 13.0772C17.7058 13.1806 17.6846 13.2829 17.6442 13.3781C17.6037 13.4733 17.5447 13.5594 17.4706 13.6316L17.4683 13.634L13.3014 17.8009C13.229 17.8735 13.143 17.931 13.0483 17.9703Z"
      fill="white"
    />
    <path
      d="M12.7393 17.7083C12.5321 17.7083 12.3333 17.5986 12.1868 17.4032C12.0403 17.2079 11.958 16.9429 11.958 16.6667V4.16667C11.958 3.8904 12.0403 3.62545 12.1868 3.4301C12.3333 3.23475 12.5321 3.125 12.7393 3.125C12.9465 3.125 13.1452 3.23475 13.2917 3.4301C13.4382 3.62545 13.5205 3.8904 13.5205 4.16667V16.6667C13.5205 16.9429 13.4382 17.2079 13.2917 17.4032C13.1452 17.5986 12.9465 17.7083 12.7393 17.7083Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0141 20.8358C20.4771 21.3728 19.7489 21.6749 18.9893 21.6758H6.48987C5.73031 21.6749 5.00211 21.3728 4.46502 20.8358C3.92797 20.2987 3.62587 19.5706 3.625 18.8111V17.7695C3.625 17.5623 3.70731 17.3636 3.85382 17.2171C4.00034 17.0706 4.19905 16.9883 4.40625 16.9883C4.61345 16.9883 4.81216 17.0706 4.95868 17.2171C5.10519 17.3636 5.1875 17.5623 5.1875 17.7695L5.1875 18.8115C5.1879 19.1566 5.32518 19.4875 5.56922 19.7316C5.81327 19.9756 6.14445 20.1129 6.48958 20.1133L18.9899 20.1133C19.335 20.1128 19.6659 19.9755 19.9099 19.7315C20.1539 19.4875 20.2912 19.1563 20.2917 18.8112V17.7695C20.2917 17.5623 20.374 17.3636 20.5205 17.2171C20.667 17.0706 20.8657 16.9883 21.0729 16.9883C21.2801 16.9883 21.4788 17.0706 21.6253 17.2171C21.7719 17.3636 21.8542 17.5623 21.8542 17.7695V18.8109C21.8533 19.5705 21.5512 20.2987 21.0141 20.8358Z"
      fill="white"
    />
  </svg>
);

export default IconWhiteDownload;
