import { HEIGHT_SOCKET_SPEC, HEIGHT_STAGE, WIDTH_STAGE } from 'src/assets/dataUI';

let SIZE_LIST = [
  {
    width: 10,
    height: HEIGHT_SOCKET_SPEC,
  },
  {
    width: 15,
    height: HEIGHT_SOCKET_SPEC,
  },
  {
    width: 25,
    height: HEIGHT_SOCKET_SPEC,
  },
];

export const SOCKET_LIST = SIZE_LIST.map((size: any, index: any) => {
  return {
    id: index + 7,
    type: 100,
    x: (WIDTH_STAGE - 50) / 2,
    y: (HEIGHT_STAGE - 20) / 2,
    width: size?.width,
    height: size?.height,
    widthMM: size?.width,
  };
});
