import SVGPathCommander from 'svg-path-commander';
class PathResolve {
  path;
  last_position;
  path_element: any = [];
  max_length;
  new_path: any = [];
  last_point: any = {
    x: 0,
    y: 0
  };

  constructor(path = '') {
    this.path = new SVGPathCommander(path).toAbsolute().toString();
    this.last_position = -1;

    this.path_element = this.path
      .replaceAll("-", " -")
      .replaceAll(new RegExp('(L)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(H)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(V)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(C)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(S)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(Q)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(T)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(M)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(A)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(l)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(h)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(v)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(c)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(s)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(q)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(t)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(a)',"ig"), ' $1 ')
      .replaceAll(new RegExp('(Z)',"ig"), ' $1').split(" ").filter((data: any) => data !== "").map((numberData: any) => {
        if (!isNaN(numberData)){
          return Number(numberData)
        }else{
          return numberData
        }
      });
    this.max_length = this.path_element.length; 
  }

  convertl (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'l'){
      this.path_element[this.last_position] = "L"
      this.path_element[this.last_position + 1]+= this.last_point.x;
      this.path_element[this.last_position + 2]+= this.last_point.y;
      this.last_point = {
        x: this.path_element[this.last_position + 1],
        y: this.path_element[this.last_position + 2]
      }
      this.last_position+=2;
    }
  }

  converth (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'h'){
      this.path_element[this.last_position] = "H"
      this.path_element[this.last_position + 1]+= this.last_point.x;
      this.last_point = {
        x: this.path_element[this.last_position + 1],
        y: this.last_point.y
      }
      this.last_position+=1;
    }
  }

  convertv (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'v'){
      this.path_element[this.last_position] = "V"
      this.path_element[this.last_position + 1]+= this.last_point.y;
      this.last_point = {
        x: this.last_point.y,
        y: this.path_element[this.last_position + 1]
      }
      this.last_position+=1;
    }
  }

  convertc (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'c'){
      this.path_element[this.last_position] = "C";
      let isFirstFlow = true;
      while (!isNaN(this.path_element[this.last_position + 1]) || this.path_element[this.last_position + 1] === 0){
        if (isFirstFlow){
          this.path_element[this.last_position + 1] = Number(this.path_element[this.last_position + 1]) + this.last_point.x;
          this.path_element[this.last_position + 2] = Number(this.path_element[this.last_position + 2]) + this.last_point.y;
          this.path_element[this.last_position + 3] = Number(this.path_element[this.last_position + 3]) + this.last_point.x;
          this.path_element[this.last_position + 4] = Number(this.path_element[this.last_position + 4]) + this.last_point.y;
          this.path_element[this.last_position + 5] = Number(this.path_element[this.last_position + 5]) + this.last_point.x;
          this.path_element[this.last_position + 6] = Number(this.path_element[this.last_position + 6]) + this.last_point.y;
    
          this.last_point = {
            x: this.path_element[this.last_position + 5],
            y: this.path_element[this.last_position + 6]
          }
          this.last_position+=6;
          isFirstFlow = false;
        }else{
          //need to move path element 1st
          for (var i = this.path_element.length - 1; i>(this.last_position);i--){
            this.path_element[i + 1] = this.path_element[i]
          }
          this.path_element[this.last_position + 1] = "C"
          this.max_length = this.path_element.length;
          this.last_position+= 1;
          this.path_element[this.last_position + 1] = Number(this.path_element[this.last_position + 1]) + this.last_point.x;
          this.path_element[this.last_position + 2] = Number(this.path_element[this.last_position + 2]) + this.last_point.y;
          this.path_element[this.last_position + 3] = Number(this.path_element[this.last_position + 3]) + this.last_point.x;
          this.path_element[this.last_position + 4] = Number(this.path_element[this.last_position + 4]) + this.last_point.y;
          this.path_element[this.last_position + 5] = Number(this.path_element[this.last_position + 5]) + this.last_point.x;
          this.path_element[this.last_position + 6] = Number(this.path_element[this.last_position + 6]) + this.last_point.y;
    
          this.last_point = {
            x: this.path_element[this.last_position + 5],
            y: this.path_element[this.last_position + 6]
          }
          this.last_position+=6;
        }
      }
    }
  }

  converts (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 's'){
      this.path_element[this.last_position] = "S"
      let isFirstFlow = true;
      while (!isNaN(this.path_element[this.last_position + 1]) || this.path_element[this.last_position + 1] === 0){
        if (isFirstFlow){
          this.path_element[this.last_position + 1]+= this.last_point.x;
          this.path_element[this.last_position + 2]+= this.last_point.y;
          this.path_element[this.last_position + 3]+= this.last_point.x;
          this.path_element[this.last_position + 4]+= this.last_point.y;
          this.last_point = {
            x: this.path_element[this.last_position + 3],
            y: this.path_element[this.last_position + 4]
          }
          this.last_position+=4;
          isFirstFlow = false;
        }else{
          //need to move path element 1st
          for (var i = (this.path_element.length - 1); i>(this.last_position);i--){
            this.path_element[i + 1] = this.path_element[i]
          }
          this.path_element[this.last_position + 1] = "S"
          this.max_length = this.path_element.length;
          this.last_position+= 1;
          this.path_element[this.last_position + 1]+= this.last_point.x;
          this.path_element[this.last_position + 2]+= this.last_point.y;
          this.path_element[this.last_position + 3]+= this.last_point.x;
          this.path_element[this.last_position + 4]+= this.last_point.y;
          this.last_point = {
            x: this.path_element[this.last_position + 3],
            y: this.path_element[this.last_position + 4]
          }
          this.last_position+=4;
        }
      }
    }
  }

  convertq (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'q'){
      this.path_element[this.last_position] = "Q"
      this.path_element[this.last_position + 1]+= this.last_point.x;
      this.path_element[this.last_position + 2]+= this.last_point.y;
      this.path_element[this.last_position + 3]+= this.last_point.x;
      this.path_element[this.last_position + 4]+= this.last_point.y;
      this.last_point = {
        x: this.path_element[this.last_position + 3],
        y: this.path_element[this.last_position + 4]
      }
      this.last_position+=4;
    }
  }

  convertt (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 't'){
      this.path_element[this.last_position] = "T"
      this.path_element[this.last_position + 1]+= this.last_point.x;
      this.path_element[this.last_position + 2]+= this.last_point.y;
      this.last_point = {
        x: this.path_element[this.last_position + 1],
        y: this.path_element[this.last_position + 2]
      }
      this.last_position+=2;
    }
  }

  addItem(item: any, index: any){
    for (let k = (this.max_length - 1); k>index;k--){
      this.path_element[k + 1] = this.path_element[k]
    }
    console.log(index + 1);
    this.path_element[index + 1] = item
    this.max_length = this.path_element.length;
  }

  converta (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'a'){
      this.path_element[this.last_position] = "A"
      let isFirstFlow = true;
      this.path_element[this.last_position + 6]+= this.last_point.x;
      this.path_element[this.last_position + 7]+= this.last_point.y;
      this.last_point = {
        x: this.path_element[this.last_position + 6],
        y: this.path_element[this.last_position + 7]
      }
      this.last_position+=7;
      isFirstFlow = false;
      while(!isNaN(this.path_element[this.last_position + 1]) || this.path_element[this.last_position + 1] == 0){
        this.addItem('A',this.last_position);
        this.last_position+= 1;
        this.path_element[this.last_position + 6]+= this.last_point.x;
        this.path_element[this.last_position + 7]+= this.last_point.y;
        this.last_point = {
          x: this.path_element[this.last_position + 6],
          y: this.path_element[this.last_position + 7]
        }
        this.last_position+=7;
      }
    }
  }

  convertm (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'm'){
      this.path_element[this.last_position] = "M"
      this.path_element[this.last_position + 1]+= this.last_point.x;
      this.path_element[this.last_position + 2]+= this.last_point.y;    
      this.last_point = {
        x: Number(this.path_element[this.last_position + 1]),
        y: Number(this.path_element[this.last_position + 2])
      }
      this.last_position+=2;
    }
  }

  convertM (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] === 'M'){
      this.path_element[this.last_position + 1] = this.path_element[this.last_position + 1];
      this.path_element[this.last_position + 2] = this.path_element[this.last_position + 2];
      this.last_point = {
        x: Number(this.path_element[this.last_position + 1]),
        y: Number(this.path_element[this.last_position + 2])
      }
      this.last_position+=2;
    }
  }

  convertL (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'L'){
      this.last_point = {
        x: Number(this.path_element[this.last_position + 1]),
        y: Number(this.path_element[this.last_position + 2])
      }
      this.last_position+=2;
    }
  }

  convertH (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'H'){
      this.last_point = {
        x: Number(this.path_element[this.last_position + 1]),
        y: this.last_point.y
      }
      this.last_position+=1;
    }
  }

  convertV (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'V'){
      this.last_point = {
        x: this.last_point.x,
        y: Number(this.path_element[this.last_position + 1]),
      }
      this.last_position+=1;
    }
  }

  convertC (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'C'){
      this.last_point = {
        x: Number(this.path_element[this.last_position + 5]),
        y: Number(this.path_element[this.last_position + 6]),
      }
      this.last_position+=6;
    }
  }

  convertS (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'S'){
      this.last_point = {
        x: Number(this.path_element[this.last_position + 3]),
        y: Number(this.path_element[this.last_position + 4]),
      }
      this.last_position+=4;
      while(!isNaN(this.path_element[this.last_position + 1]) || this.path_element[this.last_position + 1] == 0){
        this.addItem('S',this.last_position);
        this.last_position+= 1;
        
        this.last_point = {
          x: this.path_element[this.last_position + 3],
          y: this.path_element[this.last_position + 4]
        }
        this.last_position+=4; 
      }
    }
  }

  convertQ (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'Q'){
      this.last_point = {
        x: Number(this.path_element[this.last_position + 3]),
        y: Number(this.path_element[this.last_position + 4]),
      }
      this.last_position+=4;
    }
  }

  convertT (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'T'){
      this.last_point = {
        x: Number(this.path_element[this.last_position + 1]),
        y: Number(this.path_element[this.last_position + 2]),
      }
      this.last_position+=2;
    }
  }

  convertA (){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'A'){
      this.last_point = {
        x: Number(this.path_element[this.last_position + 6]),
        y: Number(this.path_element[this.last_position + 7]),
      }
      this.last_position+=7;
      while(!isNaN(this.path_element[this.last_position + 1]) || this.path_element[this.last_position + 1] == 0){
        this.addItem('A',this.last_position);
        this.last_position+= 1;
        
        // this.last_point = {
        //   x: this.path_element[this.last_position + 6],
        //   y: this.path_element[this.last_position + 7]
        // }
        this.last_position+=7; 
      }
    }
  }

  convert(){
    while (this.last_position < this.max_length){
      switch (this.path_element[this.last_position + 1]){
        case 'm':
          this.convertm();
          break;
        case 'l':
          this.convertl();
          break;
        case 'h':
          this.converth();
          break;
        case 'v':
          this.convertv();
          break;
        case 'c':
          this.convertc();
          break;
        case 's':
          this.converts();
          break;
        case 'q':
          this.convertq();
          break;
        case 't':
          this.convertt();
          break;
        case 'a':
          this.converta();
          break;
        case 'M':
          this.convertM();
          break;
        case 'L':
          this.convertL();
          break;
        case 'H':
          this.convertH();
          break;
        case 'V':
          this.convertV();
          break;
        case 'C':
          this.convertC();
          break;
        case 'S':
          this.convertS();
          break;
        case 'Q':
          this.convertQ();
          break;
        case 'T':
          this.convertT();
          break;
        case 'A':
          this.convertA();
          break;
        default: 
          // console.log(this.path,this.last_position)
          this.last_position+=1;
      }
    }
  }

  scaleM (scale: any) {
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'M'){
      this.path_element[this.last_position + 1] = Number(this.path_element[this.last_position + 1]) * scale;
      this.path_element[this.last_position + 2] = Number(this.path_element[this.last_position + 2]) * scale;
      this.last_position+=2;
    }
  }
  
  scaleL (scale: any) {
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'L'){
      this.path_element[this.last_position + 1] = Number(this.path_element[this.last_position + 1]) * scale;
      this.path_element[this.last_position + 2] = Number(this.path_element[this.last_position + 2]) * scale;
      this.last_position+=2;
    }
  }

  scaleV (scale: any) {
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'V'){
      this.path_element[this.last_position + 1] = Number(this.path_element[this.last_position + 1]) * scale;
      this.last_position+=1;
    }
  }

  scaleH (scale: any) {
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'H'){
      this.path_element[this.last_position + 1] = Number(this.path_element[this.last_position + 1]) * scale;
      this.last_position+=1;
    }
  }

  scaleC (scale: any) {
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'C'){
      this.path_element[this.last_position + 1] = Number(this.path_element[this.last_position + 1]) * scale;
      this.path_element[this.last_position + 2] = Number(this.path_element[this.last_position + 2]) * scale;
      this.path_element[this.last_position + 3] = Number(this.path_element[this.last_position + 3]) * scale;
      this.path_element[this.last_position + 4] = Number(this.path_element[this.last_position + 4]) * scale;
      this.path_element[this.last_position + 5] = Number(this.path_element[this.last_position + 5]) * scale;
      this.path_element[this.last_position + 6] = Number(this.path_element[this.last_position + 6]) * scale;
      this.last_position+=6;
    }
  }

  scaleS (scale: any) {
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'S'){
      this.path_element[this.last_position + 1] = Number(this.path_element[this.last_position + 1]) * scale;
      this.path_element[this.last_position + 2] = Number(this.path_element[this.last_position + 2]) * scale;
      this.path_element[this.last_position + 3] = Number(this.path_element[this.last_position + 3]) * scale;
      this.path_element[this.last_position + 4] = Number(this.path_element[this.last_position + 4]) * scale;
      this.last_position+=4;
    }
  }

  scaleQ (scale: any) {
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'Q'){
      this.path_element[this.last_position + 1] = Number(this.path_element[this.last_position + 1]) * scale;
      this.path_element[this.last_position + 2] = Number(this.path_element[this.last_position + 2]) * scale;
      this.path_element[this.last_position + 3] = Number(this.path_element[this.last_position + 3]) * scale;
      this.path_element[this.last_position + 4] = Number(this.path_element[this.last_position + 4]) * scale;
      this.last_position+=4;
    }
  }

  scaleT (scale: any) {
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'T'){
      this.path_element[this.last_position + 1] = Number(this.path_element[this.last_position + 1]) * scale;
      this.path_element[this.last_position + 2] = Number(this.path_element[this.last_position + 2]) * scale;
      this.last_position+=2;
    }
  }

  scaleA (scale: any) {
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'A' || this.path_element[this.last_position] == 'a'){
      this.path_element[this.last_position + 1] = Number(this.path_element[this.last_position + 1]) * scale;
      this.path_element[this.last_position + 2] = Number(this.path_element[this.last_position + 2]) * scale;
      this.path_element[this.last_position + 6] = Number(this.path_element[this.last_position + 6]) * scale;
      this.path_element[this.last_position + 7] = Number(this.path_element[this.last_position + 7]) * scale;
      this.last_position+=7;
    }
  }

  scale (scale: any){
    while (this.last_position < this.max_length){
      switch (this.path_element[this.last_position + 1]){
        case 'M':
          this.scaleM(scale);
          break;
        case 'L':
          this.scaleL(scale);
          break;
        case 'H':
          this.scaleH(scale);
          break;
        case 'V':
          this.scaleV(scale);
          break;
        case 'C':
          this.scaleC(scale);
          break;
        case 'S':
          this.scaleS(scale);
          break;
        case 'Q':
          this.scaleQ(scale);
          break;
        case 'T':
          this.scaleT(scale);
          break;
        case 'A':
          this.scaleA(scale);
          break;
        case 'a':
          this.scaleA(scale);
          break;
        default: 
          // console.log(this.path, this.last_position)
          
          this.last_position+=1;
      }
    }
    console.log(scale);
  }

  getMinM(){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'M'){
      let x = this.path_element[this.last_position + 1];
      let y = this.path_element[this.last_position + 2]
      
      this.last_position+=2;
      
      return {
        x,
        y
      }
    }else{
      return false;
    }
  }

  getMinL(){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'L'){
      let x = this.path_element[this.last_position + 1];
      let y = this.path_element[this.last_position + 2]
      
      this.last_position+=2;

      return {
        x,
        y
      }
    }else{
      return false;
    }
  }

  getMinH(){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'H'){
      let x = this.path_element[this.last_position + 1];
      
      this.last_position+=1;
      
      return {
        x,
        y: 9999
      }
    }else{
      return false;
    }
  }

  getMinV(){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'V'){
      let y = this.path_element[this.last_position + 1]
      
      this.last_position+=1;
      
      return {
        x: 99999,
        y
      }
    }else{
      return false;
    }
  }

  getMinC(){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'C'){
      let x = this.path_element[this.last_position + 5]
      let y = this.path_element[this.last_position + 6]
      
      this.last_position+=6;
      
      return {
        x,
        y
      }
    }else{
      return false;
    }
  }

  getMinS(){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'S'){
      let x = this.path_element[this.last_position + 1] > this.path_element[this.last_position + 3] ? this.path_element[this.last_position + 3] : this.path_element[this.last_position + 1]
      let y = this.path_element[this.last_position + 2] > this.path_element[this.last_position + 4] ? this.path_element[this.last_position + 4] : this.path_element[this.last_position + 2]
      
      this.last_position+=4;
      
      return {
        x,
        y
      }
    }else{
      return false;
    }
  }

  getMinQ(){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'Q'){
      let x = this.path_element[this.last_position + 3]
      let y = this.path_element[this.last_position + 4]
      
      this.last_position+=4;
      
      return {
        x,
        y
      }
    }else{
      return false;
    }
  }

  getMinT(){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'T'){
      let x = this.path_element[this.last_position + 1]
      let y = this.path_element[this.last_position + 2]
      
      this.last_position+=2;
      
      return {
        x,
        y
      }
    }else{
      return false;
    }
  }

  getMinA(){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == 'A'){
      let x = this.path_element[this.last_position + 6]
      let y = this.path_element[this.last_position + 7]
      
      this.last_position+=7;
      
      return {
        x,
        y
      }
    }else{
      return false;
    }
  }

  getStartCoordinate(){
    let output = {
      x: 99999,
      y: 99999
    }
    let outputM: any = {
      x: 9999999,
      y: 9999999
    }
    while (this.last_position < this.max_length){
      switch (this.path_element[this.last_position + 1]){
        case 'M':
          console.log('count')
          outputM = this.getMinM();
          if (outputM){
            if (outputM.x < output.x){
              output.x = outputM.x;
            }
            if (outputM.y < output.y){
              output.y = outputM.y;
            }
          }
          break;
        case 'L':
          outputM = this.getMinL();
          if (outputM){
            if (outputM.x < output.x){
              output.x = outputM.x;
            }
            if (outputM.y < output.y){
              output.y = outputM.y;
            }
          }
          break;
        case 'H':
          outputM = this.getMinH();
          if (outputM){
            if (outputM.x < output.x){
              output.x = outputM.x;
            }
            if (outputM.y < output.y){
              output.y = outputM.y;
            }
          }
          break;
        case 'V':
          outputM = this.getMinV();
          if (outputM){
            if (outputM.x < output.x){
              output.x = outputM.x;
            }
            if (outputM.y < output.y){
              output.y = outputM.y;
            }
          }
          break;
        case 'C':
          outputM = this.getMinC();
          if (outputM){
            if (outputM.x < output.x){
              output.x = outputM.x;
            }
            if (outputM.y < output.y){
              output.y = outputM.y;
            }
          }
          break;
        case 'S':
          outputM = this.getMinS();
          if (outputM){
            if (outputM.x < output.x){
              output.x = outputM.x;
            }
            if (outputM.y < output.y){
              output.y = outputM.y;
            }
          }
          break;
        case 'Q':
          outputM = this.getMinQ();
          if (outputM){
            if (outputM.x < output.x){
              output.x = outputM.x;
            }
            if (outputM.y < output.y){
              output.y = outputM.y;
            }
          }
          break;
        case 'T':
          outputM = this.getMinT();
          if (outputM){
            if (outputM.x < output.x){
              output.x = outputM.x;
            }
            if (outputM.y < output.y){
              output.y = outputM.y;
            }
          }
          break;
        case 'A':
          outputM = this.getMinA();
          if (outputM){
            if (outputM.x < output.x){
              output.x = outputM.x;
            }
            if (outputM.y < output.y){
              output.y = outputM.y;
            }
          }
          break;
        default: 
          // console.log(this.path, this.last_position)
          
          this.last_position+=1;
      }
    }
    return output
  }

  //array index should be [{addX: true, index: 1}]
  revertTransform(pathName:any, arrayIndexToChange:any, transformData:any, pathMaxIndex: any){
    this.last_position+= 1;
    if (this.path_element[this.last_position] == pathName){
      arrayIndexToChange.map((item: any, index: any) => {
        if (item?.addX){
          this.path_element[this.last_position + item?.index] -= transformData?.x
        }
        if (item?.addY){
          this.path_element[this.last_position + item?.index] -= transformData?.y
        }
      })
      
      this.last_position+=pathMaxIndex;
    }
  }

  getOutputRevert(transform: any,moveObject: any){
    while (this.last_position < this.max_length){
      switch (this.path_element[this.last_position + 1]){
        case 'M':
          this.revertTransform(
            "M",
            [{
              addX: true,
              index: 1
            },{
              addY: true,
              index: 2
            }],
            {
              x: transform?.x + (moveObject?.x || 0),
              y: transform?.y + (moveObject?.y || 0)
            },
            2
          );
          break;
        case 'L':
          this.revertTransform(
            "L",
            [{
              addX: true,
              index: 1
            },{
              addY: true,
              index: 2
            }],
            {
              x: transform?.x + (moveObject?.x || 0),
              y: transform?.y + (moveObject?.y || 0)
            },
            2
          );
          break;
        case 'H':
          this.revertTransform(
            "H",
            [{
              addX: true,
              index: 1
            }],
            {
              x: transform?.x + (moveObject?.x || 0),
              y: transform?.y + (moveObject?.y || 0)
            },
            1
          );
          break;
        case 'V':
          this.revertTransform(
            "M",
            [{
              addY: true,
              index: 1
            }],
            {
              x: transform?.x + (moveObject?.x || 0),
              y: transform?.y + (moveObject?.y || 0)
            },
            1
          );
          break;
        case 'C':
          this.revertTransform(
            "C",
            [{
              addX: true,
              index: 1
            },{
              addX: true,
              index: 3
            },{
              addX: true,
              index: 5
            },{
              addY: true,
              index: 2
            },{
              addY: true,
              index: 4
            },{
              addY: true,
              index: 6
            },],
            {
              x: transform?.x + (moveObject?.x || 0),
              y: transform?.y + (moveObject?.y || 0)
            },
            6
          );
          break;
        case 'S':
          this.revertTransform(
            "S",
            [{
              addX: true,
              index: 1
            },{
              addX: true,
              index: 3
            },{
              addY: true,
              index: 2
            },{
              addY: true,
              index: 4
            }],
            {
              x: transform?.x + (moveObject?.x || 0),
              y: transform?.y + (moveObject?.y || 0)
            },
            4
          );
          break;
        case 'Q':
          this.revertTransform(
            "Q",
            [{
              addX: true,
              index: 1
            },{
              addX: true,
              index: 3
            },{
              addY: true,
              index: 2
            },{
              addY: true,
              index: 4
            }],
            {
              x: transform?.x + (moveObject?.x || 0),
              y: transform?.y + (moveObject?.y || 0)
            },
            4
          );
          break;
        case 'T':
          this.revertTransform(
            "S",
            [{
              addX: true,
              index: 1
            },{
              addY: true,
              index: 2
            }],
            {
              x: transform?.x + (moveObject?.x || 0),
              y: transform?.y + (moveObject?.y || 0)
            },
            2
          );
          break;
        case 'A':
          this.revertTransform(
            "A",
            [{
              addX: true,
              index: 6
            },{
              addY: true,
              index: 7
            }],
            {
              x: transform?.x + (moveObject?.x || 0),
              y: transform?.y + (moveObject?.y || 0)
            },
            7
          );
          break;
        default: 
          // console.log(this.path, this.last_position)
          this.last_position+=1;
      }
    }
  }

  getPathString(){
    // console.log({pathFinal: this.path_element.join(" ")})
    return this.path_element.join(" ")
  }
}

export default PathResolve;

