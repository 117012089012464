import { PADDING_SELECT_SVG_OUTLINE } from 'src/assets/dataUI';
import { getTextMetrics, generatePointLine, calculateNewPoint, generateTransformAttributes } from '../utils';

const renderGenerateOutlineForDragSvg = (shapeInfo: any, isStepOutline: any) => {
  switch (shapeInfo?.type) {
    case 1:
      if (shapeInfo?.image_url) {
        return (
          <rect
            x={
              shapeInfo?.shapeSize?.x -
                shapeInfo?.outlinePadding * 0.5 -
                PADDING_SELECT_SVG_OUTLINE / shapeInfo?.scale?.base ?? 0
            }
            y={
              shapeInfo?.shapeSize?.y -
                shapeInfo?.outlinePadding * 0.5 -
                PADDING_SELECT_SVG_OUTLINE / shapeInfo?.scale?.base ?? 0
            }
            width={
              shapeInfo?.shapeSize?.width +
              shapeInfo?.outlinePadding +
              (2 * PADDING_SELECT_SVG_OUTLINE) / shapeInfo?.scale?.base
            }
            height={
              shapeInfo?.shapeSize?.height +
              shapeInfo?.outlinePadding +
              (2 * PADDING_SELECT_SVG_OUTLINE) / shapeInfo?.scale?.base
            }
            fill="none"
            stroke="black"
            strokeWidth={isStepOutline ? 0 : 1 / shapeInfo?.scale?.base}
            transform={generateTransformAttributes({
              shapeInfo,
            })}
          />
        );
      } else {
        return (
          <rect
            x={
              shapeInfo?.shapeSize?.x -
                PADDING_SELECT_SVG_OUTLINE / shapeInfo?.scale?.base -
                shapeInfo?.outlinePadding / 2 ?? 0
            }
            y={
              shapeInfo?.shapeSize?.y -
                PADDING_SELECT_SVG_OUTLINE / shapeInfo?.scale?.base -
                shapeInfo?.outlinePadding / 2 ?? 0
            }
            width={
              shapeInfo?.shapeSize?.width +
              shapeInfo?.outlinePadding +
              (2 * PADDING_SELECT_SVG_OUTLINE) / shapeInfo?.scale?.base
            }
            height={
              shapeInfo?.shapeSize?.height +
              shapeInfo?.outlinePadding +
              (2 * PADDING_SELECT_SVG_OUTLINE) / shapeInfo?.scale?.base
            }
            fill="none"
            stroke="black"
            strokeWidth={isStepOutline ? 0 : 1 / shapeInfo?.scale?.base}
            transform={generateTransformAttributes({
              shapeInfo,
            })}
          />
        );
      }
    // case 2:
    // case 3:
    // case 4:
    // case 5:
    // case 6:
    // return <rect x={shapeInfo?.transform?. - shapeInfo?.outlinePadding ?? 0} y={shapeInfo?.transform?.y - shapeInfo?.outlinePadding ?? 0} width={shapeInfo?.width + shapeInfo?.outlinePadding * 2} height={shapeInfo?.height + shapeInfo?.outlinePadding * 2} fill="none" stroke="black" strokeWidth="1"/>
    case 8:
      return (
        <rect
          x={
            shapeInfo?.shapeSize?.x -
              shapeInfo?.outlinePadding * 0.5 -
              PADDING_SELECT_SVG_OUTLINE / shapeInfo?.scale?.base ?? 0
          }
          y={
            shapeInfo?.shapeSize?.y -
              shapeInfo?.outlinePadding * 0.5 -
              PADDING_SELECT_SVG_OUTLINE / shapeInfo?.scale?.base ?? 0
          }
          width={
            shapeInfo?.shapeSize?.width +
            shapeInfo?.outlinePadding +
            (2 * PADDING_SELECT_SVG_OUTLINE) / shapeInfo?.scale?.base
          }
          height={
            shapeInfo?.shapeSize?.height +
            shapeInfo?.outlinePadding +
            (2 * PADDING_SELECT_SVG_OUTLINE) / shapeInfo?.scale?.base
          }
          fill="none"
          stroke="black"
          strokeWidth={isStepOutline ? 0 : 1 / shapeInfo?.scale?.base}
          transform={generateTransformAttributes({
            shapeInfo,
          })}
        />
      );
    case 9:
      return (
        <rect
          x={
            shapeInfo?.shapeSize?.x -
              shapeInfo?.outlinePadding * 0.5 -
              PADDING_SELECT_SVG_OUTLINE / shapeInfo?.scale?.base ?? 0
          }
          y={
            shapeInfo?.shapeSize?.y -
              shapeInfo?.outlinePadding * 0.5 -
              PADDING_SELECT_SVG_OUTLINE / shapeInfo?.scale?.base ?? 0
          }
          width={
            shapeInfo?.shapeSize?.width +
            shapeInfo?.outlinePadding +
            (2 * PADDING_SELECT_SVG_OUTLINE) / shapeInfo?.scale?.base
          }
          height={
            shapeInfo?.shapeSize?.height +
            shapeInfo?.outlinePadding +
            (2 * PADDING_SELECT_SVG_OUTLINE) / shapeInfo?.scale?.base
          }
          fill="none"
          stroke="black"
          strokeWidth={isStepOutline ? 0 : 1 / shapeInfo?.scale?.base}
          transform={generateTransformAttributes({
            shapeInfo,
          })}
        />
      );
    case 98:
      const pointArr = generatePointLine(calculateNewPoint(shapeInfo?.points, shapeInfo?.scale4))
        .split(' ')
        .map((p) => p.split(','))
        .map((p) => p.map(Number));
      const point1 = pointArr[0];
      const point5 = pointArr[3];
      const widthPolygon = 90;
      const heightPolygon = 94;

      return (
        <rect
          x={(shapeInfo?.transform?.x ?? 0) - (shapeInfo?.outlinePadding ?? 0) + point5[0] - 10}
          y={(shapeInfo?.transform?.y ?? 0) - (shapeInfo?.outlinePadding ?? 0) + point1[1] - 10}
          width={widthPolygon + 20}
          height={heightPolygon + 20}
          fill="none"
          stroke="black"
          strokeWidth={isStepOutline ? 0 : 1}
        />
      );

    case 99:
      const r =
        shapeInfo.smallRadius +
        2 +
        shapeInfo.borderSmallWidth +
        shapeInfo.paddingSmallCircle +
        shapeInfo?.paddingBigCircle;
      return (
        <rect
          x={shapeInfo?.startX + (shapeInfo?.transform?.x ?? 0) - (shapeInfo?.outlinePadding ?? 0) - r - 10}
          y={shapeInfo?.startY + (shapeInfo?.transform?.y ?? 0) - (shapeInfo?.outlinePadding ?? 0) - r - 10}
          width={r * 2 + 20}
          height={r * 2 + 20}
          fill="none"
          stroke="black"
          strokeWidth={isStepOutline ? 0 : 1}
        />
      );
    case 100:
      return (
        <rect
          x={shapeInfo?.x + (shapeInfo?.transform?.x ?? 0) - (shapeInfo?.outlinePadding ?? 0) - 15}
          y={shapeInfo?.y + (shapeInfo?.transform?.y ?? 0) - (shapeInfo?.outlinePadding ?? 0) - 15}
          width={shapeInfo?.width + 30}
          height={shapeInfo?.height + 30}
          fill="none"
          stroke="black"
          strokeWidth={isStepOutline ? 0 : 1}
        />
      );
    default:
      <></>;
  }
};

export const GenerateOutlineForDragSvg = ({ shapeInfo, isStepOutline }: any) => {
  return <>{renderGenerateOutlineForDragSvg(shapeInfo, isStepOutline)}</>;
};
