const IconEditorSwitch = ({ className }: any) => (
  <svg className={className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1661 32.3178H4.38857C3.87712 32.3237 3.36962 32.2439 2.89735 32.0831C2.42508 31.9224 1.99808 31.6841 1.64267 31.3831C1.27481 31.0924 0.983672 30.743 0.787209 30.3566C0.590746 29.9701 0.493126 29.5549 0.500376 29.1364V6.86355C0.493126 6.44508 0.590746 6.02985 0.787209 5.64344C0.983672 5.25703 1.27481 4.90764 1.64267 4.61685C1.99808 4.31587 2.42508 4.07764 2.89735 3.91689C3.36962 3.75615 3.87712 3.67631 4.38857 3.68224H12.1661V6.86443H4.38857V29.1374H12.1661V32.3178ZM16.0553 35.5V0.5H19.9436V35.5H16.0553ZM23.8328 32.3178V29.1356H27.7221V32.3178H23.8328ZM23.8328 6.86355V3.68131H27.7221V6.86355H23.8328ZM31.6103 32.3178V29.1356H35.4996C35.5069 29.554 35.4093 29.9693 35.2128 30.3557C35.0163 30.7421 34.7252 31.0915 34.3573 31.3823C34.0018 31.6836 33.5745 31.9221 33.1018 32.083C32.6291 32.244 32.1222 32.3239 31.6103 32.3178ZM31.6103 25.9542V22.772H35.4996V25.9542H31.6103ZM31.6103 19.5907V16.4067H35.4996V19.5889L31.6103 19.5907ZM31.6103 13.2271V10.0449H35.4996V13.2271H31.6103ZM31.6103 6.86355V3.68131C32.1218 3.67538 32.6293 3.75527 33.1016 3.91602C33.5738 4.07676 34.0008 4.31499 34.3562 4.61597C34.7241 4.90676 35.0152 5.2561 35.2117 5.64251C35.4082 6.02892 35.5058 6.44421 35.4985 6.86267L31.6103 6.86355Z"
      fill="black"
    />
  </svg>
);

export default IconEditorSwitch;
