import 'src/assets/css/main.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import LoginPage from 'src/pages/login';
import NotFoundPage from 'src/pages/notFoundPage';
import ChangePasswordPage from 'src/pages/changePasswordPage';
import ConfirmEmailPage from 'src/pages/fogotPassword/confirmEmailPage';
import ResetPasswordPage from 'src/pages/fogotPassword/resetPasswordPage';
import Layout from './components/Layout';
import ListUserPage from 'src/pages/user/ListUserPage';
import DetailUserPage from 'src/pages/user/DetailUserPage';
import EditUserPage from 'src/pages/user/EditUserPage';
import ListOrderPage from 'src/pages/order/ListOrderPage';
import DetailOrderPage from 'src/pages/order/DetailOrderPage';
import ListStaffPage from 'src/pages/staff/ListStaffPage';
import CreateEditStaffPage from 'src/pages/staff/CreateEditStaffPage';
import ListNotificationPage from 'src/pages/notification/ListNotificationPage';
import CreateEditNotificationPage from 'src/pages/notification/CreateEditNotificationPage';
import DetailNotificationPage from 'src/pages/notification/DetailNotificationPage';
import ListAcrylicPage from 'src/pages/product/ListAcrylicPage';
import DetailAcrylicPage from './pages/product/DetailAcrylicPage';
import CreateEditAcrylicPlatePage from 'src/pages/product/CreateEditAcrylicPlatePage';
import ListPartPage from 'src/pages/product/part/ListPartPage';
import DetailPartPage from 'src/pages/product/part/DetailPartPage';
import CreateEditPartPage from 'src/pages/product/part/CreateEditPartPage';
import RevenuePage from 'src/pages/revenue/RevenuePage';
import ListTemplatePage from 'src/pages/product/template/ListTemplatePage';
import CreateEditTemplatePage from 'src/pages/product/template/CreateEditTemplatePage';
import DetailTemplatePage from './pages/product/template/DetailTemplatePage';

import { Provider } from 'react-redux';
import configureKonvaEditorStore from './redux/store';
import { useEffect } from 'react';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'change-password',
        element: <ChangePasswordPage />,
      },
      {
        path: 'forgot-password',
        element: <ConfirmEmailPage />,
      },
      {
        path: 'reset-password',
        element: <ResetPasswordPage />,
      },
      {
        path: 'user',
        element: <ListUserPage />,
      },
      {
        path: 'user/:id',
        element: <DetailUserPage />,
      },
      {
        path: '/user/edit/:id',
        element: <EditUserPage />,
      },
      {
        path: '/order',
        element: <ListOrderPage />,
      },
      {
        path: '/order/:id',
        element: <DetailOrderPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
      {
        path: 'staff',
        element: <ListStaffPage />,
      },
      {
        path: 'staff/create',
        element: <CreateEditStaffPage />,
      },
      {
        path: 'staff/edit/:id',
        element: <CreateEditStaffPage />,
      },
      {
        path: 'notification',
        element: <ListNotificationPage />,
      },
      {
        path: 'notification/create',
        element: <CreateEditNotificationPage />,
      },
      {
        path: 'notification/edit/:id',
        element: <CreateEditNotificationPage />,
      },
      {
        path: 'notification/:id',
        element: <DetailNotificationPage />,
      },
      {
        path: 'product/acrylic-plates',
        element: <ListAcrylicPage />,
      },
      {
        path: 'product/acrylic-plates/:id',
        element: <DetailAcrylicPage />,
      },
      {
        path: 'product/acrylic-plates/create',
        element: <CreateEditAcrylicPlatePage />,
      },
      {
        path: 'product/acrylic-plates/edit/:id',
        element: <CreateEditAcrylicPlatePage />,
      },
      {
        path: 'product/parts',
        element: <ListPartPage />,
      },
      {
        path: 'product/parts/:id',
        element: <DetailPartPage />,
      },
      {
        path: 'product/parts/create',
        element: <CreateEditPartPage />,
      },
      {
        path: 'product/parts/edit/:id',
        element: <CreateEditPartPage />,
      },
      {
        path: 'revenue',
        element: <RevenuePage />,
      },
      {
        path: 'product/templates',
        element: <ListTemplatePage />,
      },
      {
        path: 'product/templates/create/info-general',
        element: <CreateEditTemplatePage />,
      },
      {
        path: 'product/templates/create/design',
        element: <CreateEditTemplatePage />,
      },
      {
        path: 'product/templates/create/confirm',
        element: <CreateEditTemplatePage />,
      },
      {
        path: 'product/templates/edit/:id',
        element: <CreateEditTemplatePage />,
      },
      {
        path: 'product/templates/edit/:id/design',
        element: <CreateEditTemplatePage />,
      },
      {
        path: 'product/templates/detail/:id',
        element: <DetailTemplatePage />,
      },
      {
        path: 'product/templates/edit/:id/confirm',
        element: <CreateEditTemplatePage />,
      },
    ],
  },
]);

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });
  const store = configureKonvaEditorStore();

  useEffect(() => {
    const body = document.body;
    body.style.userSelect = 'none';
    body.style.webkitUserSelect = 'none';

    return () => {
      body.style.userSelect = '';
      body.style.webkitUserSelect = '';
    };
  }, []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
