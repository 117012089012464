import { useQuery } from 'react-query';
import { api } from 'src/api/axios';

export const useListOrder = ({
  user_id,
  page,
  size,
  status,
  address,
  phone_number,
  order_code,
  keyword,
  dates,
  order_by,
  get_total,
}: {
  user_id?: string;
  page?: number;
  size?: number;
  status?: number;
  address?: string;
  phone_number?: string;
  order_code?: string;
  keyword?: string;
  dates?: Array<any>;
  order_by?: string;
  get_total?: number;
}) =>
  useQuery(
    ['listOrder', user_id, page, size, status, address, phone_number, order_code, keyword, dates, order_by, get_total],
    async () => {
      const res = await api.get('/api/cms/order/list', {
        params: {
          user_id: user_id,
          page: page,
          size: size,
          status: status,
          address: address,
          phone_number: phone_number,
          order_code: order_code,
          keyword: keyword,
          dates,
          order_by,
          get_total,
        },
      });
      return res.data.data;
    }
  );
