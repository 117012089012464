const IconEditorUp = ({ className }: any) => (
  <svg className={className} width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1677 27.2217C11.6469 27.2245 11.1317 27.1238 10.6578 26.9265C10.1839 26.7292 9.7627 26.4401 9.42328 26.0793C8.66799 25.3333 8.2584 24.349 8.28052 23.333V3.88873C8.2584 2.87273 8.66799 1.88845 9.42328 1.14252C9.7627 0.781658 10.1839 0.492523 10.6578 0.295253C11.1317 0.0979831 11.6469 -0.00273948 12.1677 5.66621e-05H31.612C32.1328 -0.00273948 32.6479 0.0979831 33.1218 0.295253C33.5957 0.492523 34.017 0.781658 34.3564 1.14252C35.1117 1.88845 35.5213 2.87273 35.4991 3.88873V23.333C35.5213 24.348 35.1125 25.3315 34.3586 26.0773C34.0192 26.4381 33.5979 26.7272 33.124 26.9245C32.6501 27.1217 32.135 27.2225 31.6142 27.2197L12.1677 27.2217ZM12.1677 23.333H31.612V3.88873H12.1677V23.333ZM4.39011 31.1103V34.999C3.86937 35.0018 3.35419 34.9011 2.8803 34.7038C2.40641 34.5066 1.98516 34.2174 1.64575 33.8566C0.889754 33.1109 0.479381 32.1266 0.500798 31.1103H4.39011ZM0.500798 27.2217V23.333H4.39011V27.2217H0.500798ZM0.500798 19.4444V15.5557H4.39011V19.4444H0.500798ZM0.500798 11.6671V7.77838H4.39011V11.6671H0.500798ZM8.27833 35V31.1113H12.1677V35H8.27833ZM16.0559 35V31.1113H19.9452V35H16.0559ZM23.8334 35V31.1113H27.7249V35H23.8334Z"
      fill="black"
    />
  </svg>
);

export default IconEditorUp;
