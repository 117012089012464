const IconEditorDuplicate = ({ className }: any) => (
  <svg className={className} width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8534 28.0012C12.3117 28.0078 11.7742 27.92 11.2741 27.7432C10.7739 27.5665 10.3216 27.3045 9.94518 26.9734C9.55569 26.6534 9.24745 26.2689 9.0395 25.8437C8.83154 25.4186 8.72828 24.9617 8.73606 24.5013V3.5002C8.72828 3.03979 8.83154 2.58292 9.0395 2.15775C9.24745 1.73258 9.55569 1.34813 9.94518 1.02813C10.3216 0.697039 10.7739 0.435036 11.2741 0.258265C11.7742 0.0814944 12.3117 -0.00627322 12.8534 0.00034865H31.3823C31.9239 -0.00627322 32.4614 0.0814944 32.9616 0.258265C33.4618 0.435036 33.914 0.697039 34.2905 1.02813C34.68 1.34813 34.9882 1.73258 35.1962 2.15775C35.4041 2.58292 35.5074 3.03979 35.4996 3.5002V24.5004C35.5074 24.9608 35.4041 25.4177 35.1962 25.8428C34.9882 26.268 34.68 26.6525 34.2905 26.9725C33.914 27.3035 33.4618 27.5656 32.9616 27.7423C32.4614 27.9191 31.9239 28.0069 31.3823 28.0002L12.8534 28.0012ZM12.8534 24.5013H31.3823V3.5002H12.8534V24.5013ZM4.61768 34.9991C4.07605 35.0057 3.53857 34.9179 3.03839 34.7411C2.53822 34.5644 2.08595 34.3024 1.7095 33.9713C1.32027 33.6515 1.01219 33.2673 0.804244 32.8425C0.596297 32.4177 0.492888 31.9611 0.50038 31.5011V7.00011H4.61768V31.5001H27.2639V35L4.61768 34.9991Z"
      fill="black"
    />
  </svg>
);

export default IconEditorDuplicate;
