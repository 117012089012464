import { TypeLangFontTextEditor } from "src/assets/dataUI";
import styles from "./index.module.scss";
import classNames from "classnames";

interface IProps {
  langFont: TypeLangFontTextEditor;
  onPickFont: (font: any) => void;
  fontCurrent: any;
  listFontEn: any;
  listFontJa: any;
}

export default function FontTextPicker({
  langFont,
  onPickFont,
  fontCurrent,
  listFontEn,
  listFontJa,
}: IProps) {
  const listFontCurrent =
    langFont === TypeLangFontTextEditor.JA ? listFontJa : listFontEn;
  return (
    <div className={styles.fontPicker}>
      <button type="button" className={styles.dropdownButton}>
        <p className={styles.dropdownFontFamily}>{fontCurrent?.name}</p>
        <p className="dropdown-icon finished"></p>
      </button>
      <ul className={styles.fontList}>
        {listFontCurrent?.map((item: any, index: any) => {
          return (
            <li className={styles.fontListItem} key={index}>
              <button
                type="button"
                className={classNames(styles.fontButton, [
                  {
                    [styles.fontButton__choosed]:
                      fontCurrent?.fontFamily === item?.family,
                  },
                ])}
                style={{ fontFamily: item?.family }}
                onClick={() => onPickFont(item)}
              >
                {item?.name}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
