import { ROLE_ADMIN } from 'src/assets/constants';
import { getCookie, getLocalStorage } from './cookie';

export const getToken = (): string => {
  return getCookie('token') || '';
};

export const isFirstLogin = (): boolean => {
  return getCookie('is_first_login') === '1' ? true : false;
};

export const isAdmin = (): boolean => {
  return getLocalStorage('role') === String(ROLE_ADMIN) ? true : false;
};
