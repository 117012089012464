const IconEditorDown = ({ className }: any) => (
  <svg className={className} width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.38841 34.9996C3.87698 35.0069 3.3695 34.9092 2.89726 34.7127C2.42502 34.5162 1.99802 34.2251 1.64262 33.8572C1.27479 33.5017 0.983681 33.0747 0.787229 32.6024C0.590777 32.1301 0.49315 31.6225 0.500373 31.111V7.77855H4.38841V31.111H27.7176V34.9996H4.38841ZM12.1645 23.3329V27.2215C11.653 27.2286 11.1454 27.1308 10.6732 26.9342C10.2009 26.7375 9.77397 26.4461 9.41869 26.078C9.05082 25.7226 8.75967 25.2956 8.56322 24.8233C8.36676 24.351 8.26916 23.8434 8.27644 23.3319L12.1645 23.3329ZM8.27644 19.4443V15.5557H12.1645V19.4443H8.27644ZM8.27644 11.6661V7.77758H12.1645V11.6661H8.27644ZM12.1645 3.88898H8.27644C8.26922 3.37749 8.36684 2.86991 8.5633 2.3976C8.75975 1.92529 9.05087 1.49822 9.41869 1.14277C9.77421 0.774768 10.2014 0.483553 10.6738 0.287069C11.1462 0.0905854 11.6539 -0.00697408 12.1655 0.000387658L12.1645 3.88898ZM16.0525 27.2215V23.3329H19.9405V27.2215H16.0525ZM16.0525 3.88898V0.000387658H19.9405V3.88898H16.0525ZM23.8286 3.88898V0.000387658H27.7166V3.88898H23.8286ZM23.8286 27.2215V23.3329H27.7166V27.2215H23.8286ZM31.6047 3.88898V0.000387658C32.1161 -0.00683649 32.6236 0.0907732 33.0958 0.287253C33.568 0.483732 33.995 0.774892 34.3504 1.14277C34.7183 1.49822 35.0094 1.92529 35.2058 2.3976C35.4023 2.86991 35.4999 3.37749 35.4927 3.88898H31.6047ZM31.6047 23.3329H35.4997C35.5062 23.8446 35.4077 24.3522 35.2104 24.8243C35.0131 25.2965 34.7211 25.7232 34.3524 26.078C33.9971 26.446 33.5701 26.7371 33.0978 26.9336C32.6256 27.1301 32.1181 27.2277 31.6067 27.2204L31.6047 23.3329ZM31.6047 19.4443V15.5557H35.4997V19.4443H31.6047ZM31.6047 11.6661V7.77758H35.4997V11.6661H31.6047Z"
      fill="black"
    />
  </svg>
);

export default IconEditorDown;
