import * as React from 'react';

const IconChangeWidth = ({ width = 19, height = 19, ...props }: any) => (
  <svg {...props} width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 18.507V12.338H2.056V15.011L5.243 11.824L6.682 13.263L3.495 16.45H6.168V18.506L0 18.507ZM13.264 6.68298L11.825 5.24402L15.012 2.05701H12.339V0H18.508V6.16901H16.452V3.5L13.264 6.68298Z"
      fill="black"
    />
  </svg>
);

export default IconChangeWidth;
