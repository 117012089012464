const IconFirst = ({ className }: any) => (
  <svg className={className} width={9} height={15} viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.713125 15V13.48H3.97313V2.56H1.35313V1.38C2.67313 1.16 3.61313 0.819999 4.39313 0.339999H5.79313V13.48H8.75313V15H0.713125Z"
      fill="#c0c0c0"
    />
  </svg>
);

export default IconFirst;
