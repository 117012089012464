const IconEditItem = ({ className }: any) => (
  <svg className={className} width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8371 11.083L7.86414 23.756C7.41206 24.2372 6.97456 25.1851 6.88706 25.8414L6.34748 30.5664C6.15789 32.2726 7.38289 33.4393 9.07456 33.1476L13.7704 32.3455C14.4266 32.2289 15.3454 31.7476 15.7975 31.2518L27.7704 18.5789C29.8412 16.3914 30.7746 13.8976 27.5516 10.8497C24.3433 7.83096 21.9079 8.89554 19.8371 11.083Z"
      stroke="black"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8398 13.1992C18.4669 17.2242 21.7336 20.3013 25.7878 20.7096"
      stroke="black"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.875 37.918H31.125"
      stroke="black"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconEditItem;
