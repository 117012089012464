import { api } from "src/api/axios";

export const handleRemoveBgApi = (params: any) => {
  return api.post(`api/user/remove-bg`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: 'arraybuffer'
  })
};



