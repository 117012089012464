import ImageTracer from 'imagetracerjs';

export const optionsFormSampling = {
  colorquantcycles: 2,
  colorsampling: true, // I recommend true (or false but not null)
  desc: false,
  ltres: 1,
  mincolorratio: 5,
  // pal: [
  //   {r:0,g:0,b:0,a:255},
  //   {r:255,g:255,b:255,a:255}
  // ],
  numberofcolors: 2,
  pathomit: 100,
  qtres: 7,
  roundcoords: 2,
  linefilter: true,
  rightangleenhance: true,
};

export const optionsPathSampling = {
  colorquantcycles: 2,
  colorsampling: true, // I recommend true (or false but not null)
  desc: false,
  ltres: 1,
  mincolorratio: 5,
  // pal: [
  //   {r:0,g:0,b:0,a:255},
  //   {r:255,g:255,b:255,a:255}
  // ],
  numberofcolors: 2,
  pathomit: 10,
  qtres: 1,
  roundcoords: 1,
};

export const generateSvgFromUrl = (object: any, callback: any) => {
  if (typeof object == 'string') {
    let url = object;
    ImageTracer.imageToSVG(
      url,
      function (svgst: any) {
        if (callback) {
          callback(svgst);
        }
      },
      optionsFormSampling //'posterized1'
    );
  } else {
    ImageTracer.imageToSVG(
      object?.url,
      function (svgst: any) {
        if (callback) {
          callback(svgst);
        }
      },
      object?.options
    );
  }
};
