import { Button, Form, Input, Select, Spin, message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import iconSelect from 'src/assets/images/arrow-down-2.png';
import ModalConfirm from 'src/components/common/modal/modalConfirm';
import { ROLE } from 'src/assets/constants';
import IconArrowLeft from 'src/assets/icon/IconArrowLeft';
import { StaffCreateType, useCreateStaff } from 'src/api/useCreateStaff';
import { useDetailStaff } from 'src/api/useDetailStaff';
import { StaffEditType, useEditStaff } from 'src/api/useEditStaff';
import { useDeleteStaff } from 'src/api/useDeleteStaff';
import IconTrash from 'src/assets/icon/IconTrash';
import NotFoundPage from 'src/pages/notFoundPage';

const StaffCreateEditComponent = () => {
  const location = useLocation();
  const [antForm] = Form.useForm();
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const [messError, setMessError] = useState<string>('');
  const [isVisibleModelConfirmBack, setIsVisibleModelConfirmBack] = useState<boolean>(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState<boolean>(true);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [phone1, setPhone1] = useState<string>('');
  const [phone2, setPhone2] = useState<string>('');
  const [phone3, setPhone3] = useState<string>('');
  const [messageErrorPhone1, setMessageErrorPhone1] = useState<string>('');
  const [messageErrorPhone2, setMessageErrorPhone2] = useState<string>('');
  const [messageErrorPhone3, setMessageErrorPhone3] = useState<string>('');
  const isInValidPhoneNumber =
    ((!!phone1?.length || !!phone2?.length || !!phone3?.length) &&
      !(!!phone1?.length && !!phone2?.length && !!phone3?.length)) ||
    !!messageErrorPhone1 ||
    !!messageErrorPhone2 ||
    !!messageErrorPhone3;

  const { data: detailData, isLoading, isError } = useDetailStaff(Number(id));
  const { isLoading: isLoadingCreate, mutate: onCreateStaff } = useMutation(
    (param: StaffCreateType) => useCreateStaff(param),
    {
      onSuccess: () => {
        message.success('アカウント登録に成功しました');
        history('/staff');
        antForm.resetFields();
      },
      onError: (error: any) => {
        if (error.response?.status === 400) {
          setMessError('指定のメールアドレスは既に使用されています');
          return;
        }
        message.error('アカウント登録は失敗しました。再度確認してください');
      },
    }
  );

  const { isLoading: isLoadingEdit, mutate: onEditStaff } = useMutation(
    (param: StaffEditType) => useEditStaff(param, Number(id)),
    {
      onSuccess: () => {
        message.success('アカウント情報の編集に成功しました');
        history('/staff');
        antForm.resetFields();
        queryClient.clear();
      },
      onError: () => {
        message.error('アカウント編集は失敗しました。再度確認してください');
      },
    }
  );

  const handleCreateEditStaff = async () => {
    await trimmedValue();
    try {
      await antForm.validateFields();
    } catch {
      return;
    }
    if (messageErrorPhone1 === '' && messageErrorPhone2 === '' && messageErrorPhone3 === '' && !isDisableSubmit) {
      const valueForm = antForm.getFieldsValue();
      const params = {
        name: valueForm.name,
        email: valueForm.email,
        phone_1: phone1,
        phone_2: phone2,
        phone_3: phone3,
        role: valueForm.role,
      };
      if (id) {
        onEditStaff(params);
      } else {
        onCreateStaff(params);
      }
    }
  };

  const { isLoading: isLoadingDelete, mutate: onDelete } = useMutation((id: number | string) => useDeleteStaff(id), {
    onSuccess: async () => {
      message.success('アカウント削除は成功しました');
      setIsOpenModalDelete(false);
      history('/staff');
    },
    onError: () => {
      message.error('アカウント削除は失敗しました。再度確認してください');
    },
  });

  const handleGoBackStaffList = () => {
    history('/staff');
  };

  const handleCancel = () => {
    setIsVisibleModelConfirmBack(false);
  };

  const handleGoBack = () => {
    history(-1);
  };

  const trimmedValue = async () => {
    const allValueForm = antForm.getFieldsValue();
    Object.keys(allValueForm).forEach((key: any) => {
      if (typeof allValueForm[key] === typeof '') {
        antForm.setFieldsValue({ [key]: allValueForm[key]?.trim() });
      }
    });
  };

  const validateFullName = (rule: any, value: string, callback: any) => {
    if (!value?.trim()) {
      callback(`氏名を入力してください`);
    } else if (value?.trim()?.length > 15) {
      callback(`氏名は15文字以内で入力してください`);
    } else {
      callback();
    }
  };

  const validateEmail = (rule: any, value: string, callback: any, length: number = 255) => {
    const emailRegex = /^[a-zA-Z0-9._+-]+@([\w-]+\.)+([a-zA-Z]+)$/;
    if (!value?.trim()) {
      callback('メールアドレスを入力してください');
    } else if (!emailRegex.test(value.trim())) {
      callback('メールアドレスの形式が不正です');
    } else if (length && value?.trim()?.length > length) {
      callback(`メールアドレスは${length}文字以内で入力してください`);
    } else {
      callback();
    }
  };

  const renderSelectIcon = () => {
    return <img src={iconSelect} alt="icon-select" />;
  };

  const handleResetMessError = () => {
    setMessError('');
  };

  const handleConfirmDelete = () => {
    if (id) {
      onDelete(id);
    }
  };

  const handleCloseModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const handleOpenModalDelete = () => {
    setIsOpenModalDelete(true);
  };

  const handleChangePhone1 = (e: any) => {
    const value = e.target.value;
    setPhone1(value);
  };

  const handleChangePhone2 = (e: any) => {
    const value = e.target.value;
    setPhone2(value);
  };

  const handleChangePhone3 = (e: any) => {
    const value = e.target.value;
    setPhone3(value);
  };

  const validatePhone1 = (rule: any, value: string, callback: any) => {
    const phoneRegex = /^([0-9]{2,4}$)+$|^$/i;
    if (value && !phoneRegex.test(value.trim())) {
      callback();
      setMessageErrorPhone1('各ブロックは半角数字２文字以上４文字以内で入力してください');
    } else {
      callback();
      setMessageErrorPhone1('');
    }
  };

  const validatePhone2 = (rule: any, value: string, callback: any) => {
    const phoneRegex = /^([0-9]{2,4}$)+$|^$/i;
    if (value && !phoneRegex.test(value.trim())) {
      callback();
      setMessageErrorPhone2('各ブロックは半角数字２文字以上４文字以内で入力してください');
    } else {
      callback();
      setMessageErrorPhone2('');
    }
  };

  const validatePhone3 = (rule: any, value: string, callback: any) => {
    const phoneRegex = /^([0-9]{2,4}$)+$|^$/i;
    if (value && !phoneRegex.test(value.trim())) {
      setMessageErrorPhone3('各ブロックは半角数字２文字以上４文字以内で入力してください');
    } else {
      callback();
      setMessageErrorPhone3('');
    }
  };

  const handleChangeValue = (allvalues: any) => {
    const listValue = [allvalues?.email, allvalues?.name, allvalues?.role];
    const validateErrorValueList: any[] = [];
    listValue?.forEach((value: any) => {
      if (value === undefined || value === '') {
        validateErrorValueList.push(value);
      }
    });
    if (validateErrorValueList.length > 0) {
      setIsDisableSubmit(true);
    } else {
      setIsDisableSubmit(false);
    }
  };

  useEffect(() => {
    if (!detailData) return;
    if (detailData?.phone_1) {
      setPhone1(detailData?.phone_1);
    }
    if (detailData?.phone_2) {
      setPhone2(detailData?.phone_2);
    }
    if (detailData?.phone_3) {
      setPhone3(detailData?.phone_3);
    }
  }, [detailData]);

  useEffect(() => {
    if (id) {
      setIsDisableSubmit(false);
    }
  }, [id]);

  if (location.pathname?.includes('edit')) {
    if (!detailData || Object.keys(detailData).length === 0) {
      if (isError) {
        return <NotFoundPage />;
      }
      return null;
    }
  }

  return (
    <div className="construction-create page-content company-page">
      <div className="page-content-title border-bottom-title mb-[30px] flex items-center justify-between">
        <div className="inline-flex items-center cursor-pointer" onClick={handleGoBack}>
          <div className="mr-[17px]">
            <IconArrowLeft />
          </div>
          <span className="line-1">
            {location.pathname?.includes('edit') ? '社内ユーザーの編集' : '社内ユーザーの追加'}
          </span>
        </div>

        {location.pathname?.includes('edit') && (
          <div className="flex">
            <Button className="button-remove min-w-[128px] min-h-[48px] ml-[8px]" onClick={handleOpenModalDelete}>
              <IconTrash className="mr-[10px]" /> 削除
            </Button>
          </div>
        )}
      </div>

      {isLoading ? (
        <Spin className="position-center" />
      ) : (
        <Form
          form={antForm}
          className="form-create w-[900px] mx-auto"
          layout="vertical"
          autoComplete="off"
          onValuesChange={(value, allvalues) => handleChangeValue(allvalues)}
        >
          <div className="pb-[12px]">
            <div className="mb-[16px]">
              <Form.Item
                required
                label="氏名"
                name="name"
                initialValue={detailData?.name}
                rules={[{ validator: (rule, value, callback) => validateFullName(rule, value, callback) }]}
              >
                <Input className="input-form" placeholder="氏名を入力してください" />
              </Form.Item>
            </div>

            <div className="mb-[16px]">
              <Form.Item
                required
                label="メールアドレス"
                name="email"
                initialValue={detailData?.email}
                rules={[{ validator: (rule, value, callback) => validateEmail(rule, value, callback) }]}
              >
                <Input
                  disabled={location.pathname.includes('/edit') ? true : false}
                  className="input-form"
                  placeholder="メールアドレスを入力してください"
                  onChange={handleResetMessError}
                />
              </Form.Item>
              {messError && <p className="invalid-feedback whitespace-pre-line">{messError}</p>}
            </div>
            <div className="mb-[16px]">
              <label htmlFor="phone_1" className="form-label-phone">
                電話番号
              </label>
              <div className="form-input-phone flex gap-[10px] items-center">
                <Form.Item
                  name="phone_1"
                  initialValue={detailData?.phone_1}
                  rules={[
                    {
                      validator: (rule, value, callback) => validatePhone1(rule, value, callback),
                    },
                  ]}
                >
                  <Input
                    className="input-form w-[100px]"
                    placeholder="0000"
                    onChange={handleChangePhone1}
                    maxLength={4}
                  />
                </Form.Item>
                <span className="mark">－</span>
                <Form.Item
                  name="phone_2"
                  initialValue={detailData?.phone_2}
                  rules={[
                    {
                      validator: (rule, value, callback) => validatePhone2(rule, value, callback),
                    },
                  ]}
                >
                  <Input
                    className="input-form w-[100px]"
                    placeholder="0000"
                    onChange={handleChangePhone2}
                    maxLength={4}
                  />
                </Form.Item>
                <span className="mark">－</span>
                <Form.Item
                  name="phone_3"
                  initialValue={detailData?.phone_3}
                  rules={[
                    {
                      validator: (rule, value, callback) => validatePhone3(rule, value, callback),
                    },
                  ]}
                >
                  <Input
                    className="input-form w-[100px]"
                    placeholder="0000"
                    onChange={handleChangePhone3}
                    maxLength={4}
                  />
                </Form.Item>
              </div>
              {messageErrorPhone1 || messageErrorPhone2 || messageErrorPhone3 ? (
                <p className="invalid-feedback whitespace-pre-line">
                  {messageErrorPhone1 || messageErrorPhone2 || messageErrorPhone3}
                </p>
              ) : isInValidPhoneNumber ? (
                <p className="invalid-feedback whitespace-pre-line">電話番号を入力してください</p>
              ) : (
                ''
              )}
            </div>
            <div className="mb-[16px]">
              <Form.Item
                required
                label="ロール"
                name="role"
                rules={[{ required: true, message: 'ロールを選択してください' }]}
                initialValue={detailData?.role}
              >
                <Select
                  className="company-input-select h-[51px]"
                  allowClear={true}
                  options={ROLE}
                  suffixIcon={renderSelectIcon()}
                  placeholder="ロールを選択してくだいさい"
                />
              </Form.Item>
            </div>
          </div>

          <div className="footer-form flex">
            <Button className="button-form mr-[16px]" onClick={handleGoBack}>
              キャンセル
            </Button>
            <Button
              disabled={isDisableSubmit || isInValidPhoneNumber}
              className="button-form button-form-Submit"
              loading={isLoadingCreate || isLoadingEdit}
              onClick={handleCreateEditStaff}
            >
              <span></span>
              {location.pathname?.includes('edit') ? 'OK' : '追加'}
            </Button>
          </div>
        </Form>
      )}
      <ModalConfirm
        isShowModal={isVisibleModelConfirmBack}
        onCancel={handleCancel}
        onOk={handleGoBackStaffList}
        content="このページを離れますか？"
        subContent="ページから移動すると、保存されていない変更は失われます。"
      />
      <ModalConfirm
        isShowModal={isOpenModalDelete}
        onCancel={handleCloseModalDelete}
        onOk={handleConfirmDelete}
        content="削除確認"
        subContent="選択したユーザーを削除してもよろしいですか？"
        loadingButtonOk={isLoadingDelete}
        isClose={false}
        isIcon={false}
        textOk="削除"
      />
    </div>
  );
};

export default StaffCreateEditComponent;
