import { HEIGHT_STAGE, WIDTH_HOLE, WIDTH_STAGE } from "src/assets/dataUI";

let RADIUS_LIST = [WIDTH_HOLE, WIDTH_HOLE + 2, WIDTH_HOLE + 5];

export const HOLE_LIST = RADIUS_LIST.map((radius: any, index: any) => {
  return {
    id: index + 1,
    type: 99,
    startX: WIDTH_STAGE / 2,
    startY: HEIGHT_STAGE / 2,
    smallRadius: radius,
    paddingSmallCircle: 2,
    borderSmallWidth: 0,
    paddingBigCircle: 0,
  };
});

const HOLE_99 = {
  id: 9,
  type: 98,
  points: [
    {
      x: 100,
      y: 10,
    },
    {
      x: 40,
      y: 198,
    },
    {
      x: 190,
      y: 78,
    },
    {
      x: 10,
      y: 78,
    },
    {
      x: 160,
      y: 198,
    },
  ],
  scale1: 0.2,
  scale2: 0.4,
  scale3: 0.3,
  scale4: 0.5,
};
