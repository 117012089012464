import { api } from 'src/api/axios';

export interface UserEditType {
  full_name: string;
  address: string;
  phone_1: string;
  phone_2: string;
  phone_3: string;
  post_code: string;
}

export const useEditUser = (params: UserEditType, id: number) => api.post(`/api/cms/user/${id}`, params);
