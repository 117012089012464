import { Button, Modal } from 'antd';
import moment from 'moment';
import 'src/components/features/superAdmin/detailContent.modules.scss';
import bgMobile from 'src/assets/images/bg-mobile.png';
import IconBack from 'src/assets/icon/IconBack';
import { useEffect, useState } from 'react';

interface ModalPreviewNotifyType {
  isOpenModalPreview: boolean;
  handleCloseModalPreview: () => void;
  title: string;
  created_at: string;
  content: string;
}

const ModalPreviewNotify = ({
  isOpenModalPreview,
  handleCloseModalPreview,
  title,
  created_at,
  content,
}: ModalPreviewNotifyType) => {
  const [contentCurrent, setContentCurrent] = useState<any>('');

  useEffect(() => {
    setContentCurrent(contentCurrent);
  }, [isOpenModalPreview]);

  return (
    <Modal
      closable={true}
      centered={true}
      width={375}
      open={isOpenModalPreview}
      onCancel={handleCloseModalPreview}
      footer={null}
      bodyStyle={{ height: 667, maxHeight: 667, overflow: 'auto', padding: '30px 15px' }}
      className="modal-preview-notify"
    >
      <img src={bgMobile} alt="" className="layer-mobile" />
      <div className="inner-preview">
        <h1 className="detail-content-title mt-[20px]">{title}</h1>
        <div className="detail-content-date">{moment(created_at).utc().format('YYYY/MM/DD')}</div>
        <div className="detail-content-divider my-[20px]" />
        <div className="detail-content-description" dangerouslySetInnerHTML={{ __html: content }}></div>
        <div className="flex justify-center mt-auto">
          <div className="detail-content-back h-[46px] w-[190px]">
            お知らせ一覧に戻る
            <IconBack className="detail-back-icon" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalPreviewNotify;
