import {
  HIGHLIGHT_STATUS,
  NOTIFICATION_STATUS,
  NOTIFY_STATUS_HIDE,
  NOTIFY_STATUS_SHOW,
  ORDER_STATUS,
  ORDER_STATUS_ACCEPTED,
  ORDER_STATUS_PROCESSING,
  ORDER_STATUS_SUCCESS,
  ROLE,
} from 'src/assets/constants';
import { saveAs } from 'file-saver';

export const findRepeatedObjects = (array: []) => {
  const repeatedObjects: any = [];
  array?.forEach((obj: any) => {
    if (!repeatedObjects?.find((item: any) => item.y === obj.y)) {
      repeatedObjects.push(obj);
    }
  });

  return repeatedObjects;
};

export const handleZoomCanvas = (canvas: any) => {
  let isDragging: any;
  let lastPosX: any;
  let lastPosY: any;
  let spaceKey: any;

  document.onkeydown = function (e) {
    if (e.keyCode === 32) {
      spaceKey = true;
      canvas?.getObjects().forEach((object: any) => {
        object.selectable = false;
      });
      e.preventDefault();
    }
  };

  document.onkeyup = function (e) {
    if (e.keyCode === 32) {
      spaceKey = false;
      canvas?.getObjects().forEach((object: any) => {
        object.selectable = true;
      });
      e.preventDefault();
    }
  };

  canvas?.on('mouse:down', (opt: any) => {
    const evt = opt.e;
    if (spaceKey === true) {
      isDragging = true;
      lastPosX = evt.clientX;
      lastPosY = evt.clientY;
    }
  });

  canvas?.on('mouse:move', (opt: any) => {
    if (isDragging) {
      const { e } = opt;
      const vpt = canvas.viewportTransform;
      vpt[4] += e.clientX - lastPosX;
      vpt[5] += e.clientY - lastPosY;
      canvas.requestRenderAll();
      lastPosX = e.clientX;
      lastPosY = e.clientY;
    }
  });
  canvas?.on('mouse:up', () => {
    canvas.setViewportTransform(canvas.viewportTransform);
    isDragging = false;
  });

  canvas?.on('mouse:wheel', (opt: any) => {
    if (opt.e.ctrlKey) {
      const delta = opt.e.deltaY;
      let zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    }
  });
};

export const handleGetRole = (statusId: number | undefined) => {
  return ROLE?.find((status: { value: number | undefined }) => status.value === statusId)?.label;
};

// 1.Tiếp nhận đơn hàng- 注文受付
// 2.Xử lý đơn hàng -処理中
// 3.Giao hàng thành công -配送済
// 4. Cancel -キャンセル

export const getClassStatusOrder = (statusId: number | undefined) => {
  switch (statusId) {
    case ORDER_STATUS_ACCEPTED:
      return 'order-accepted';
    case ORDER_STATUS_PROCESSING:
      return 'order-processing';
    case ORDER_STATUS_SUCCESS:
      return 'order-success';
    default:
      return 'order-cancel';
  }
};

export const handleGetStatusOrder = (statusId: number | undefined) => {
  return ORDER_STATUS?.find((status: { value: number | undefined }) => status.value === statusId)?.label;
};

export const trimmedValue = async (antForm: any) => {
  const allValueForm = antForm.getFieldsValue();
  Object.keys(allValueForm).forEach((key: any) => {
    if (typeof allValueForm[key] === typeof '') {
      antForm.setFieldsValue({ [key]: allValueForm[key]?.trim() });
    }
  });
};

// 1. 表示: Hiển thị notify
// 0. 非表示: Không hiển thị notify
export const getClassStatusNotify = (statusId: number | undefined) => {
  switch (statusId) {
    case NOTIFY_STATUS_SHOW:
      return 'notify-show';
    case NOTIFY_STATUS_HIDE:
      return 'notify-hide';
    default:
      return '';
  }
};

export const handleGetStatusNotify = (statusId: number | undefined) => {
  return NOTIFICATION_STATUS?.find((status: { value: number | undefined }) => status.value === statusId)?.label;
};

// 1. あり: Hiển thị highlight
// 0. なし: Không hiển thị highlight
export const handleGetStatusHighlight = (statusId: number | undefined) => {
  return HIGHLIGHT_STATUS?.find((status: { value: number | undefined }) => status.value === statusId)?.label;
};

export const formatPrice = (number: string | number) => {
  return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

export const getIndexTable = (pageIndex: number, pageSize: number, current: number) => {
  return (pageIndex - 1) * pageSize + current + 1;
};

export const getTotalPriceCartNotFee = (dataCart: any) => {
  let newTotalMoney = 0;
  if (dataCart) {
    dataCart?.forEach((item: any) => {
      newTotalMoney += item?.price * item?.quantity;
    });
  }
  return newTotalMoney;
};

export const dataURLtoBlob = (dataURL: any) => {
  const byteString = atob(dataURL.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: 'image/png' });
};

export const decimalUpToSeven = (x: number) => Number(x.toFixed(7));

export function clamp(value: any, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export function handleDownload(image_url: any, image: any) {
  fetch(image_url, {
    method: 'GET',
    headers: {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
  })
    .then((res) => res.blob())
    .then((blob) => saveAs(blob, image))
    .catch((err) => {
      console.log(err.message);
    });
}

export const nodeToObject = (node: any) => {
  var obj = {
    attributes: {},
    children: [],
  } as any;

  // Check if the node has a tagName (for element nodes)
  if (node.tagName) {
    // obj.tagName = node.tagName.toLowerCase();
    obj.tagName = node.tagName;

    // Get attributes
    var attrs = node.attributes;
    for (var i = 0; i < attrs.length; i++) {
      var attr = attrs[i];
      obj.attributes[attr.name] = attr.value;
    }
  }

  // Get child nodes
  var children = node.childNodes;
  for (var j = 0; j < children.length; j++) {
    var child = children[j];
    if (child.nodeType === Node.TEXT_NODE) {
      obj.content = child.textContent;
    } else if (child.nodeType === Node.ELEMENT_NODE) {
      obj.children.push(nodeToObject(child));
    }
  }

  return obj;
};

export const getElementSvgFile = async (image_url: any) => {
  return new Promise((resolve, reject) => {
    let infoElementSvg: any;
    // Convert the DOM object into a JavaScript object

    if (image_url) {
      try {
        fetch(image_url, {
          method: 'GET',
          headers: {
            pragma: 'no-cache',
            'cache-control': 'no-cache',
          },
        })
          .then((res) => res.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.readAsText(blob);
            reader.onloadend = () => {
              const svgString = reader?.result as any;
              const parser = new DOMParser();
              const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
              infoElementSvg = nodeToObject(svgDoc);
              resolve(infoElementSvg?.children?.[0]);
            };
          })
          .catch((err) => reject(err));
      } catch (err) {
        reject(err);
      }
    }
  });
};

export function cropVisibleArea(
  imageUrl: any,
  callBack: any
): Promise<{ width: number; height: number; croppedImage: string }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = imageUrl;
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      // console.log(img, img.naturalHeight, img.naturalWidth);
      // Resize canvas to fit the dimensions of the image
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the entire image onto the canvas
      context?.drawImage(img, 0, 0, img.width, img.height);

      // Get the image data
      const imageData = context?.getImageData(0, 0, canvas.width, canvas.height);
      if (!imageData) {
        reject('Failed to get image data.');
        return;
      }

      // Find the boundaries of the visible area
      let minX = canvas.width;
      let minY = canvas.height;
      let maxX = 0;
      let maxY = 0;

      // Iterate through each pixel to find non-transparent pixels
      for (let y = 0; y < canvas.height; y++) {
        for (let x = 0; x < canvas.width; x++) {
          const alpha = imageData.data[(y * canvas.width + x) * 4 + 3];
          if (alpha > 0) {
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
          }
        }
      }

      console.log({
        maxY, minY, minX, maxX
      })
      const width = maxX - minX + 1;
      const height = maxY - minY + 1;
      // Create a new canvas to hold the cropped image
      const croppedCanvas = document.createElement('canvas');
      const croppedContext = croppedCanvas.getContext('2d');
      if (!croppedContext) {
        reject('Failed to create cropped canvas context.');
        return;
      }

      croppedCanvas.width = width;
      croppedCanvas.height = height;

      // Draw the visible area onto the cropped canvas without background
      croppedContext.drawImage(img, minX, minY, width, height, 0, 0, width, height);

      // Convert the cropped canvas to base64 image
      const croppedImage = croppedCanvas.toDataURL();

      if (typeof callBack == 'function') {
        callBack(croppedImage, width, height, minX, minY, img.width, img.height);
      }
      resolve({ width, height, croppedImage });
    };
    img.onerror = function (error) {
      reject(error);
      window.alert('Something error here! Please reupload image!!');
      // message.error("Something error here! Please reupload image!!")
    };
  });
}

export function deepClone(obj: any) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (obj instanceof SVGRect) {
    return {
      x: obj.x,
      y: obj.y,
      width: obj.width,
      height: obj.height,
    };
  }

  let clone: any = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    clone[key] = deepClone(obj[key]);
  }
  return clone;
}
