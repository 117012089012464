const IconBlackDownload = ({ className }: any) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.062 23.0019C15.9408 23.0521 15.8109 23.0779 15.6797 23.0777C15.5485 23.0779 15.4177 23.0521 15.2965 23.0019C15.1753 22.9517 15.0649 22.8776 14.9722 22.7847L9.63892 17.4514L9.6359 17.4484C9.5411 17.356 9.46561 17.2457 9.41378 17.1239C9.36196 17.002 9.33484 16.8711 9.334 16.7388C9.33317 16.6064 9.35862 16.4751 9.4089 16.3527C9.45917 16.2302 9.53326 16.1189 9.62688 16.0253C9.72049 15.9317 9.83176 15.8576 9.95423 15.8073C10.0767 15.7571 10.2079 15.7316 10.3403 15.7324C10.4727 15.7333 10.6036 15.7604 10.7254 15.8122C10.8473 15.864 10.9576 15.9396 11.05 16.0344L15.6793 20.6637L20.3056 16.0374L20.3085 16.0343C20.401 15.9395 20.5113 15.864 20.6331 15.8122C20.7549 15.7604 20.8858 15.7333 21.0182 15.7324C21.1506 15.7316 21.2818 15.7571 21.4043 15.8073C21.5268 15.8576 21.638 15.9317 21.7317 16.0253C21.8253 16.1189 21.8994 16.2302 21.9496 16.3527C21.9999 16.4751 22.0254 16.6064 22.0245 16.7388C22.0237 16.8711 21.9966 17.002 21.9447 17.1239C21.8929 17.2457 21.8174 17.356 21.7226 17.4484L21.7196 17.4514L16.386 22.7851C16.2933 22.878 16.1832 22.9516 16.062 23.0019Z"
      fill="#2E2E2E"
    />
    <path
      d="M15.666 22.6667C15.4008 22.6667 15.1464 22.5262 14.9589 22.2761C14.7714 22.0261 14.666 21.687 14.666 21.3333V5.33333C14.666 4.97971 14.7714 4.64057 14.9589 4.39052C15.1464 4.14048 15.4008 4 15.666 4C15.9312 4 16.1856 4.14048 16.3731 4.39052C16.5607 4.64057 16.666 4.97971 16.666 5.33333V21.3333C16.666 21.687 16.5607 22.0261 16.3731 22.2761C16.1856 22.5262 15.9312 22.6667 15.666 22.6667Z"
      fill="#2E2E2E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2581 26.6689C25.5706 27.3564 24.6385 27.7431 23.6663 27.7441H7.66703C6.6948 27.7431 5.7627 27.3564 5.07523 26.6689C4.3878 25.9815 4.00111 25.0495 4 24.0773V22.7441C4 22.4789 4.10536 22.2246 4.29289 22.037C4.48043 21.8495 4.73478 21.7441 5 21.7441C5.26522 21.7441 5.51957 21.8495 5.70711 22.037C5.89464 22.2246 6 22.4789 6 22.7441L6 24.0779C6.00051 24.5196 6.17623 24.9432 6.4886 25.2555C6.80098 25.5679 7.2249 25.7436 7.66667 25.7441L23.6671 25.7441C24.1088 25.7435 24.5323 25.5678 24.8447 25.2555C25.157 24.9431 25.3327 24.5192 25.3333 24.0775V22.7441C25.3333 22.4789 25.4387 22.2246 25.6262 22.037C25.8138 21.8495 26.0681 21.7441 26.3333 21.7441C26.5986 21.7441 26.8529 21.8495 27.0404 22.037C27.228 22.2246 27.3333 22.4789 27.3333 22.7441V24.0771C27.3323 25.0493 26.9456 25.9814 26.2581 26.6689Z"
      fill="#2E2E2E"
    />
  </svg>
);

export default IconBlackDownload;
