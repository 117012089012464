import { useEffect, useState } from 'react';
import { MenyStepChangeSize } from '../../SvgMerge/menu';
import styles from 'src/components/features/superAdmin/EditorComponent/FinalStep/index.module.scss';
import stylesText from '../index.module.scss';
import {
  FONT_SIZE_DEFAULT,
  FONT_SIZE_TEXT_RANGE_SLIDER,
  HEIGHT_STAGE,
  OUTLINE_PADDING_DEFAULT,
  OUTLINE_PADDING_TEXT_RANGE_SLIDER,
  RANGE_SLIDER_STEP_SIZE,
  RANGE_SLIDER_STEP_SIZE_FONT_SIZE,
  SCALE_RANGE_SLIDER,
  SIZE_TEXT_RANGE_SLIDER,
  WIDTH_STAGE,
} from 'src/assets/dataUI';
import { generateTextShapeData } from '../handle/utils';
import { nanoid } from 'nanoid';
import useItem from 'src/hook/useItem';
import SvgMerge from '../../SvgMerge';
import MenuBottom from 'src/components/common/menuBottom';
import { Button, Slider } from 'antd';
import {
  calculateSvgSize,
  getPathMinxMiny,
  revertCalculationBeforeTransform,
  revertPathStringByTransform,
} from '../../SvgMerge/utils';
import { cropVisibleArea } from 'src/utils';
import { useRatioContext } from 'src/hook/useChangeRatio';

const Step4AddText = ({
  text,
  textStyle,
  handleSetFeature,
  textLength,
  svgContent,
  setSvgContent,
  setStep,
  defaultStep,
  editMode,
  baseSvgContent,
}: any) => {
  const { createItem, updateItem } = useItem();
  const [currentTab, setCurrentTab] = useState(0);
  const {stateRatio} = useRatioContext()
  const [scaleSize, setScaleSize] = useState(
    defaultStep === 3 && editMode ? svgContent?.scale?.base : SCALE_RANGE_SLIDER
  );
  const [outlinePadding, setOutlinePadding] = useState<any>(
    defaultStep === 3 && editMode ? svgContent?.outlinePadding : OUTLINE_PADDING_DEFAULT
  );
  const [fontSizeFinal, setFontSizeFinal] = useState<any>(textStyle?.fontSize ?? FONT_SIZE_DEFAULT + 'px');
  // const [letterSpacing, setLetterSpacing] = useState<any>(
  //   textStyle?.letterSpacing
  // );

  useEffect(() => {
    setFontSizeFinal(textStyle?.fontSize ?? '40px');
  }, [textStyle]);

  // useEffect(() => {
  //   if (fontSizeFinal !== FONT_SIZE_DEFAULT + "px") {
  //     setLetterSpacing(
  //       Number(
  //         textStyle?.letterSpacing?.slice(
  //           0,
  //           textStyle?.letterSpacing?.length - 2
  //         )
  //       ) +
  //         (Number(fontSizeFinal?.slice(0, fontSizeFinal.length - 2)) -
  //           FONT_SIZE_DEFAULT) /
  //           10 +
  //         "px"
  //     );
  //   }
  // }, [fontSizeFinal]);

  useEffect(() => {
    if (editMode) {
      setOutlinePadding(textStyle?.outlinePadding);
      setScaleSize(svgContent?.scale?.base);
    }
  }, [textStyle, svgContent, editMode]);

  // useEffect(() => {
  //   if (defaultStep === 3 && editMode) {
  //     onSubmitFinal({
  //       textData: svgContent?.text,
  //       textStyleData: svgContent?.textStyle,
  //       textLengthData: textLength,
  //     });
  //   }
  // }, [defaultStep, editMode]);

  const onSubmitFinal = ({ textData, textStyleData, textLengthData }: any) => {
    let shapeSize = calculateSvgSize({
      idToCheck: 'svg_outline_shape',
      baseShapeInfo: svgContent,
      isText: true,
    });
    shapeSize = revertCalculationBeforeTransform({
      transform: {
        x: 0,
        y: 0,
      },
      scale: {
        base: 1,
      },
      rotate: {
        a: 0,
        x: shapeSize.width / 2,
        y: shapeSize.height / 2,
        baseScale: 1,
      },
      outputSvgSize: shapeSize,
    });

    if (svgContent?.image_url) {
      let moveObject = getPathMinxMiny({
        pathStringArray: svgContent?.children?.map((pathInfo: any) => {
          return pathInfo?.attributes?.d;
        }),
        transform: {
          x: shapeSize?.x - svgContent?.transform?.x,
          y: shapeSize?.y - svgContent?.transform?.y,
        },
      });

      let newPath = svgContent?.children?.map((pathInfo: any) => {
        return {
          ...pathInfo,
          attributes: {
            ...pathInfo?.attributes,
            d: revertPathStringByTransform({
              pathString: pathInfo?.attributes?.d,
              transform: {
                x: shapeSize?.x - svgContent?.transform?.x,
                y: shapeSize?.y - svgContent?.transform?.y,
              },
              moveObject,
            }),
          },
        };
      });

      cropVisibleArea(
        svgContent?.image_url,
        (imageBase64Data: any, width: any, height: any, minX: any, minY: any, baseWidth: any, baseHeight: any) => {
          let newAttrs = {
            zIndex: 1,
            shapeData: generateTextShapeData({
              text: textData,
              textStyle: {
                ...textStyleData,
                outlinePadding: outlinePadding,
                // letterSpacing: letterSpacing,
              },
              textLength: textLengthData,
              ...{
                svgContent: svgContent?.image_url
                  ? {
                      ...svgContent,
                      attributes: {
                        ...svgContent?.attributes,
                        width: shapeSize.width,
                        height: shapeSize.height,
                        viewBox: `0 0 ${shapeSize.width} ${shapeSize.height}`,
                      },
                      scale: {
                        base: scaleSize,
                      },
                      children: newPath,
                      image_url: imageBase64Data,
                      shapeSize: {
                        x: 0,
                        y: 0,
                        width: shapeSize?.width,
                        height: shapeSize?.height,
                      },
                    }
                  : {
                      ...svgContent,
                      scale: {
                        base: scaleSize,
                      },
                    },
              },
              baseSvgContent,
              newWidth: stateRatio?.ratioWidth,
              newHeight: stateRatio?.ratioHeight
            }),
          };

          if (editMode) {
            updateItem(baseSvgContent?.id, (prevData) => ({
              ...newAttrs,
            }));
          } else {
            createItem({
              id: nanoid(),
              attrs: newAttrs,
            });
          }
          handleSetFeature();
        }
      );
    } else {
      let newAttr2 = {
        zIndex: 1,
        shapeData: generateTextShapeData({
          text: textData,
          textStyle: {
            ...textStyleData,
            outlinePadding: outlinePadding,
          },
          textLength: textLengthData,
          svgContent: {
            ...svgContent,
            fontSize: fontSizeFinal,
          },
          baseSvgContent,
          editMode,
          newWidth: stateRatio?.ratioWidth,
          newHeight: stateRatio?.ratioHeight
        }),
      };

      if (editMode) {
        updateItem(baseSvgContent?.id, (prevData) => ({
          ...newAttr2,
          zIndex: baseSvgContent?.attrs?.zIndex,
        }));
      } else {
        createItem({
          id: nanoid(),
          attrs: newAttr2,
        });
      }
      handleSetFeature();
    }
  };

  return (
    <>
      {editMode && <div className="flex justify-center mb-3 text-[24px] text-[#515c6f]">カットライン調整</div>}

      <div className={styles.editor}>
        <div
          className={styles.stage}
          style={{
            width: stateRatio?.ratioWidth,
            height: stateRatio?.ratioHeight,
          }}
        >
          <SvgMerge
            shapeData={generateTextShapeData({
              text,
              textStyle: {
                ...textStyle,
                outlinePadding: outlinePadding,
                // letterSpacing: letterSpacing,
              },
              textLength,
              svgContent: {
                ...svgContent,
                fontSize: fontSizeFinal,
              },
              baseSvgContent,
              noRotate: true,
              newWidth: stateRatio?.ratioWidth,
              newHeight: stateRatio?.ratioHeight
            })}
            outline={{
              showOutline: true,
              outlinePadding: OUTLINE_PADDING_DEFAULT,
              outlineWidth: 1,
              outlineColor: 'black',
              paddingColor: 'white',
            }}
          />
        </div>
      </div>

      <div className="my-react-add-text flex justify-center mb-2">
        <div className="w-[500px]">
          <div className={stylesText.content}>
            {currentTab === 1 && (
              <>
                <p className={stylesText.scaleTitle}>拡大・縮小</p>

                <div className={stylesText.scale}>
                  <Slider
                    tooltip={{ formatter: null }}
                    defaultValue={Number(fontSizeFinal?.slice(0, fontSizeFinal?.length - 2))}
                    min={FONT_SIZE_DEFAULT - FONT_SIZE_TEXT_RANGE_SLIDER}
                    max={FONT_SIZE_DEFAULT + FONT_SIZE_TEXT_RANGE_SLIDER}
                    step={RANGE_SLIDER_STEP_SIZE_FONT_SIZE}
                    onChange={(e) => {
                      setFontSizeFinal(Number(e) + 'px');
                    }}
                  />
                </div>
              </>
            )}
            {currentTab === 2 && (
              <>
                <p className={stylesText.scaleTitle}>縁取りサイズ調整</p>

                <div className={stylesText.scale}>
                  <Slider
                    tooltip={{ formatter: null }}
                    defaultValue={outlinePadding}
                    min={OUTLINE_PADDING_DEFAULT - OUTLINE_PADDING_TEXT_RANGE_SLIDER}
                    max={OUTLINE_PADDING_DEFAULT + OUTLINE_PADDING_TEXT_RANGE_SLIDER}
                    step={RANGE_SLIDER_STEP_SIZE}
                    onChange={(e) => {
                      setOutlinePadding(Number(e));
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <MenuBottom
        list={MenyStepChangeSize({
          onClickChangeWidth: () => setCurrentTab(1),
          onClickEnlarge: () => setCurrentTab(2),
        })}
      />

      <div className="flex justify-center gap-2 mt-3">
        <Button
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            if (editMode) {
              handleSetFeature();
            } else {
              setStep((prev: number) => prev - 1);
            }
          }}
        >
          {'<<前のステップへ'}
        </Button>
        <Button
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            onSubmitFinal({
              textData: text,
              textStyleData: textStyle,
              textLengthData: textLength,
            });
          }}
        >
          OK
        </Button>
      </div>
    </>
  );
};

export default Step4AddText;
