import React from "react";
import {
	generatePointLine,
	calculateNewPoint
} from "../../utils";

const SocketSvgPolygon = ({
	shapeInfo,
	outline,
	drawOutline,
	handleClick,
	index,
}: any) => {
	if (drawOutline) {
		return (
			<polygon
				id={index}
				onClick={handleClick}
				points={generatePointLine(
					calculateNewPoint(shapeInfo?.points, shapeInfo?.scale4)
				)}
				stroke="black"
				strokeWidth={1}
				transform={
					shapeInfo?.transform
						? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})`
						: ""
				}
			/>
		);
	} else {
		return <></>;
	}
};

export default SocketSvgPolygon;
