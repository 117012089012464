import { useEffect, useState } from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAdmin, isFirstLogin } from 'src/utils/auth';

const itemsMenuStaff: MenuProps['items'] = [
  {
    label: '販売商品管理',
    key: 'product',
    children: [
      {
        label: '香水ラベル',
        key: 'product/acrylic-plates',
      },
      {
        label: 'パーツ',
        key: 'product/parts',
      },
      {
        label: 'テンプレート',
        key: 'product/templates',
      },
    ],
  },
  {
    label: '注文管理',
    key: 'order',
  },
];

const itemsMenuAdmin = [
  ...itemsMenuStaff,
  {
    label: '売り上げ管理',
    key: 'revenue',
  },
  {
    label: 'LINEユーザー管理 ',
    key: 'user',
  },
  {
    label: '社内ユーザー管理',
    key: 'staff',
  },
  {
    label: 'お知らせ管理',
    key: 'notification',
  },
];

const ListMenu = () => {
  const location = useLocation();
  const [current, setCurrent] = useState('');
  const history = useNavigate();

  const onClick: MenuProps['onClick'] = (e) => {
    if (isFirstLogin()) return;
    setCurrent(e.key);
    history(`/${e.key}`);
  };

  useEffect(() => {
    let items = isAdmin() ? itemsMenuAdmin : itemsMenuStaff;
    let selectedMenu = items.find((item) => location.pathname.includes(String(item?.key)));
    if (!selectedMenu) {
      if (location.pathname === '/change-password') {
        setCurrent('');
      } else {
        setCurrent('order');
        history('/order');
      }
    } else {
      setCurrent(String(selectedMenu.key));
      if ('children' in selectedMenu) {
        let selectedSubMenu = selectedMenu?.children?.find((item) => location.pathname.includes(String(item?.key)));
        if (selectedSubMenu) {
          setCurrent(String(selectedSubMenu.key));
        }
      }
    }
  }, [location, isAdmin()]);

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={isAdmin() ? itemsMenuAdmin : itemsMenuStaff}
      className="header-menu"
      style={{ flex: 'auto', minWidth: 0 }}
    />
  );
};

export default ListMenu;
