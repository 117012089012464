import useHotkeyFunc from './useHotkeyFunc';

const useTool = (
  selectedItems: any,
  setIsShowModal: (val: boolean) => void,
  setEdittingIndex: (val: number) => void,
  handleEdit: (val: 3 | 4 | undefined) => void
) => {
  const hotkeyFunc = useHotkeyFunc();

  const handleEditItem = () => {
    // Cate panel don't need to show modal
    if (selectedItems?.attrs?.shapeData[0]?.cate === 'panel') {
      handleEdit && handleEdit(4);
      return;
    } else if (selectedItems?.attrs?.shapeData[0]?.type === 1) {
      handleEdit && handleEdit(3);
      return;
    }
    setIsShowModal(true);
  };

  const getClickCallback = (id: string, ratioSize: any) => () => {
    switch (id) {
      case 'flip-horizontally':
        return hotkeyFunc.flipHorizontally(selectedItems, setEdittingIndex);
      case 'flip-vertically':
        return hotkeyFunc.flipVertically(selectedItems);
      case 'layer-up':
        return hotkeyFunc.layerUp(selectedItems, setEdittingIndex);
      case 'layer-down':
        return hotkeyFunc.layerDown(selectedItems, setEdittingIndex);
      case 'duplicate':
        return hotkeyFunc.duplicateItems(selectedItems, ratioSize);
      case 'edit':
        return handleEditItem();
      default:
        return null;
    }
  };

  return {
    getClickCallback,
  };
};

export default useTool;
