import React from 'react';

const SocketSvg = ({ shapeInfo, outline, drawOutline, handleClick, index }: any) => {
  const r =
    shapeInfo.smallRadius + 2 + shapeInfo.borderSmallWidth + shapeInfo.paddingSmallCircle + shapeInfo?.paddingBigCircle;

  if (drawOutline) {
    return (
      <circle
        id={index}
        onClick={handleClick}
        cx={shapeInfo?.startX}
        cy={shapeInfo?.startY}
        r={
          shapeInfo.smallRadius + shapeInfo.borderSmallWidth + shapeInfo.paddingSmallCircle
          // +
          // shapeInfo?.paddingBigCircle
        }
        stroke="black"
        strokeWidth={1}
        fill="black"
        transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
      />
    );
  } else {
    return (
      <g
        id={index}
        onClick={handleClick}
        transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
      >
        <image
          x={shapeInfo?.startX + (shapeInfo?.outlinePadding ?? 0) - r - 10}
          y={shapeInfo?.startY + (shapeInfo?.outlinePadding ?? 0) - r - 10}
          width={r * 2 + 20}
          height={r * 2 + 20}
          preserveAspectRatio="none"
        />
        <circle
          cx={shapeInfo?.startX}
          cy={shapeInfo?.startY}
          r={shapeInfo.smallRadius + shapeInfo.borderSmallWidth + shapeInfo.paddingSmallCircle}
          fill="none"
        />
      </g>
    );
  }
};

export default SocketSvg;
