import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconArrowLeft from 'src/assets/icon/IconArrowLeft';
import Loading from 'src/components/common/loading';
import 'src/components/features/superAdmin/detailContent.modules.scss';
import { Button, message, Form, Input, Spin, Select } from 'antd';
import IconTrash from 'src/assets/icon/IconTrash';
import ModalConfirm from 'src/components/common/modal/modalConfirm';
import { useMutation } from 'react-query';
import IconEdit from 'src/assets/icon/IconEdit';
import NotFoundPage from 'src/pages/notFoundPage';
import { useDetailPart } from 'src/api/useDetailPart';
import { useDeletePart } from 'src/api/useDeletePart';
import { useListPartCategories } from 'src/api/useListPartCategories';
import { TypeCategoryPart } from 'src/assets/constants/enums';

const PartDetailComponent = () => {
  const [antForm] = Form.useForm();
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { data: dataPartCategories } = useListPartCategories();
  const { data: detailData, isLoading: isLoadingDetail, refetch, isError } = useDetailPart(Number(id));
  const dataSelectCategories =
    dataPartCategories?.data?.map((item: any) => {
      return {
        value: item?.id,
        label: item?.name,
        type: item?.type,
      };
    }) || [];
  const typeCategoryPartCurrent = dataSelectCategories?.find(
    (item: any) => item?.value === detailData?.part_category_id
  )?.type;
  const isHasSizeCategoryPart = typeCategoryPartCurrent === TypeCategoryPart.HAS_SIZE;

  const { isLoading: isLoadingDelete, mutate: onDelete } = useMutation((id: number | string) => useDeletePart(id), {
    onSuccess: async () => {
      refetch();
      message.success('カテゴリー削除は成功しました');
      setIsOpenModalDelete(false);
      history('/product/parts');
    },
    onError: () => {
      message.error('システムにエラーが発生しました。後でもう一度お試しください');
    },
  });
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);

  const handleGoBack = () => {
    history('/product/parts');
  };

  const handleConfirmDelete = () => {
    if (id) {
      onDelete(id);
    }
  };

  const handleCloseModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const handleOpenModalDelete = () => {
    setIsOpenModalDelete(true);
  };

  const handleGoToEditPart = () => {
    history(`/product/parts/edit/${id}`);
  };

  if ((!detailData || Object.keys(detailData).length === 0 || isError) && !isLoadingDetail) {
    return <NotFoundPage />;
  }

  return (
    <>
      {isLoadingDetail ? (
        <Loading />
      ) : (
        <div className="page-content company-page">
          <div className="page-content-title border-bottom-title mb-[30px] flex items-center justify-between">
            <div className="inline-flex items-center cursor-pointer" onClick={handleGoBack}>
              <div className="mr-[17px]">
                <IconArrowLeft />
              </div>
              <span className="line-1">カテゴリーの詳細</span>
            </div>

            <div className="flex items-center justify-end px-[12px] py-[8px]">
              <div className="flex gap-[20px] ml-auto">
                <Button className="button-add h-[48px] w-[115px]" onClick={handleGoToEditPart}>
                  <IconEdit className="mr-[10px]" />
                  編集
                </Button>
                <Button className="button-remove w-[115px] h-[48px]" onClick={handleOpenModalDelete}>
                  <IconTrash className="mr-[10px]" /> 削除
                </Button>
              </div>
            </div>
          </div>
          <Form form={antForm} className="form-create w-[900px] mx-auto" layout="vertical" autoComplete="off">
            <div className="pb-[12px]">
              <div className={`mb-[16px]`}>
                <Form.Item
                  required
                  label="カテゴリー名"
                  name="part_category_id"
                  initialValue={detailData?.part_category_id}
                >
                  <Select
                    className={`select-type-part ${!!id ? 'select-part-disable' : ''}`}
                    options={dataSelectCategories}
                    placeholder="カテゴリー名を選択してください"
                    disabled
                  />
                </Form.Item>
              </div>
              <div className={`mb-[16px]`}>
                <Form.Item required label="サブカテゴリー名" name="name" initialValue={detailData?.name}>
                  <Input disabled className="input-form" placeholder="サブカテゴリー名を入力してください" />
                </Form.Item>
              </div>
              {isHasSizeCategoryPart ? (
                <Form.List name="parts" initialValue={detailData?.parts}>
                  {(partList, { add, remove }) => (
                    <>
                      {partList?.map((item: any, index: any) => {
                        return (
                          <div className="mt-[30px] bg-blue p-[10px]" key={item?.key}>
                            <div className="mb-[30px]">
                              <Form.Item required label="カテゴリー画像" name={[item?.name, 'images']}>
                                <div className="w-[200px] h-[200px]">
                                  <img
                                    src={detailData?.parts?.[index]?.file_path}
                                    alt="thumbnail"
                                    className="thumbnail-upload"
                                  />
                                </div>
                              </Form.Item>
                            </div>
                            <div className="mb-[30px] flex items-center gap-[30px]">
                              <Form.Item required label="高さ" name={[item?.name, 'height']} className="w-[257px]">
                                <Input disabled className="input-form" />
                              </Form.Item>
                              <Form.Item required label="幅" name={[item?.name, 'width']} className="w-[257px]">
                                <Input disabled className="input-form" />
                              </Form.Item>
                              <div className="w-[257px]" />
                            </div>
                            <div className="mb-[30px] flex items-center gap-[30px]">
                              <Form.Item required label="メモ" name={[item?.name, 'memo']} className="w-[257px]">
                                <Input disabled className="input-form" />
                              </Form.Item>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </Form.List>
              ) : (
                <div className="mt-[30px] bg-blue p-[10px] pt-[23px]">
                  <Form.Item required label="カテゴリー画像" name={['image_url']}></Form.Item>
                  <div className="flex flex-wrap">
                    {detailData?.parts?.map((item: any, index: number) => {
                      return (
                        <div
                          className={`w-[200px] h-[200px] mb-[40px] ${
                            index !== 0 && index % 4 === 3 ? '' : 'mr-[26px]'
                          }`}
                          key={index}
                        >
                          <img src={item?.file_path} alt="thumbnail" className="thumbnail-upload image-part-detail" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </Form>
        </div>
      )}
      <ModalConfirm
        isShowModal={isOpenModalDelete}
        onCancel={handleCloseModalDelete}
        onOk={handleConfirmDelete}
        content="削除確認"
        subContentPrefix="選択した「"
        subContent={detailData?.name}
        subContentSuffix="」を削除してもよろしいですか？"
        loadingButtonOk={isLoadingDelete}
        isClose={false}
        isIcon={false}
        textOk="削除"
      />
    </>
  );
};

export default PartDetailComponent;
