const IconBagTick = ({ className }: any) => (
  <svg className={className} width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.71875 16L11.2188 17.5L14.4688 14.5"
      stroke="#C0C0C0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.90906 2L5.28906 5.63"
      stroke="#C0C0C0"
      strokeWidth="1.5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2891 2L18.9091 5.63"
      stroke="#C0C0C0"
      strokeWidth="1.5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.09961 7.84961C2.09961 5.99961 3.08961 5.84961 4.31961 5.84961H19.8796C21.1096 5.84961 22.0996 5.99961 22.0996 7.84961C22.0996 9.99961 21.1096 9.84961 19.8796 9.84961H4.31961C3.08961 9.84961 2.09961 9.99961 2.09961 7.84961Z"
      stroke="#C0C0C0"
      strokeWidth="1.5"
    />
    <path
      d="M3.59961 10L5.00961 18.64C5.32961 20.58 6.09961 22 8.95961 22H14.9896C18.0996 22 18.5596 20.64 18.9196 18.76L20.5996 10"
      stroke="#C0C0C0"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default IconBagTick;
