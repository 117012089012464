const IconArrowDown = ({ className }: any) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={12} height={9} viewBox="0 0 12 9" fill="none">
    <path
      d="M11.3285 0C11.7286 0 11.9667 0.446625 11.7436 0.778785L6.50552 8.57739C6.30658 8.87358 5.87035 8.87207 5.67347 8.57451L0.51339 0.775904C0.293459 0.443515 0.531813 0 0.930375 0H11.3285Z"
      fill="#0F0F0F"
    />
  </svg>
);

export default IconArrowDown;
