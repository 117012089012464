const IconSubForm = ({ className }: any) => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0003 0.166992C9.50033 0.166992 0.166992 9.50033 0.166992 21.0003C0.166992 32.5003 9.50033 41.8337 21.0003 41.8337C32.5003 41.8337 41.8337 32.5003 41.8337 21.0003C41.8337 9.50033 32.5003 0.166992 21.0003 0.166992ZM31.417 23.0837H23.0837H18.917H10.5837V18.917H18.917H23.0837H31.417V23.0837Z"
      fill="#E33636"
    />
  </svg>
);

export default IconSubForm;
