import { configureStore, EntityState, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import stageDataReducer, { StageData, stageDataEpic } from './currentStageData';

export type StoreState = {
  currentStageData: EntityState<StageData['attrs']>;
  stageDataList: EntityState<any>;
};

const rootEpic = combineEpics(stageDataEpic);

const configureKonvaEditorStore = (preloadedState?: StoreState) => {
  const epicMiddleware = createEpicMiddleware();

  const store = configureStore({
    reducer: {
      currentStageData: stageDataReducer,
    },
    middleware: getDefaultMiddleware({ serializableCheck: false }).concat(epicMiddleware),
    preloadedState,
  });
  epicMiddleware.run(rootEpic);
  return store;
};

export default configureKonvaEditorStore;
