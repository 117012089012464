import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Input, Button, Form, Modal } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import success from 'src/assets/images/tick-circle.png';
import error from 'src/assets/images/error-circle.png';
import { checkResetPasswordExpired, resetPassword } from 'src/api/userInfor';
import Loading from 'src/components/common/loading';

interface TypeFormResetPass {
  token: string;
  password: string;
}
const ResetPassword = () => {
  const location = useLocation();
  const history = useNavigate();
  const [antForm] = Form.useForm();
  const [messError, setMessError] = useState<string>('');
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState<boolean>(false);
  const [isOpenModalError, setIsOpenModalError] = useState<boolean>(false);
  // eslint-disable-next-line
  const urlSearchParams = new URLSearchParams(location.search);
  const { isLoading, mutate: onResetPassword } = useMutation((param: TypeFormResetPass) => resetPassword(param), {
    onSuccess: () => {
      setIsOpenModalSuccess(true);
    },
    onError: () => {
      setIsOpenModalError(true);
    },
  });

  const { isLoading: isLoadingExpired, mutate: onCheckResetPasswordExpired } = useMutation(
    (param: { token: string }) => checkResetPasswordExpired(param),
    {
      onError: () => {
        setIsOpenModalError(true);
      },
    }
  );

  const handleSendEmailForgotPassword = useCallback(async () => {
    try {
      await antForm.validateFields();
    } catch {
      return;
    }
    const values = antForm.getFieldsValue();
    const tokenResetPassword = urlSearchParams.get('token');
    const params = {
      password: values.password,
      token: String(tokenResetPassword),
    };
    return onResetPassword(params);
  }, [antForm, onResetPassword, urlSearchParams]);

  // eslint-disable-next-line
  const handleChange = (value: string) => {
    setMessError('');
  };

  const handleEnterSubmit = (e: any) => {
    if (e.key === 'Enter') {
      handleSendEmailForgotPassword();
    }
  };

  // eslint-disable-next-line
  const validateNewPassword = (rule: any, value: string, callback: any) => {
    const passwordRegex =
      // eslint-disable-next-line
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[_~?&!@#%|,.<>=:;\\/\-\$\^\[\]\{\}\+\'\"\*\(\)])[A-Za-z0-9_~?&!@#%|,.<>=:;\\/\-\$\^\[\]\{\}\+\'\"\*\(\)]+$/;
    if (!value?.trim()) {
      callback(`新しいパスワードを入力してください`);
    } else if (value?.trim()?.length < 8 || value?.trim()?.length > 32) {
      callback(`パスワードは半角英数字8文字以上32文字以内で入力してください`);
    } else if (!passwordRegex.test(value?.trim())) {
      callback(`パスワードには大文字、小文字、数字、特殊文字を少なくとも1つ含むことです`);
    } else {
      callback();
    }
  };

  // eslint-disable-next-line
  const validateConfirmPassword = (rule: any, value: string, callback: any) => {
    const valueForm = antForm.getFieldsValue();
    if (!value?.trim()) {
      callback(`新しいパスワード確認を入力してください`);
    } else if (valueForm['password'] !== value) {
      callback(`新しいパスワード確認は新しいパスワードと一致しないといけません`);
    } else {
      callback();
    }
  };

  const handleCloseModalSuccess = () => {
    setIsOpenModalSuccess(false);
    history('/login');
  };

  const handleCloseModalError = () => {
    setIsOpenModalError(false);
    history('/forgot-password');
  };

  const handleGoBack = () => {
    history('/login');
  };

  const handleCheckResetPasswordExpired = () => {
    const tokenResetPassword = urlSearchParams.get('token');
    const params = {
      token: tokenResetPassword!,
    };
    onCheckResetPasswordExpired(params);
  };

  useEffect(() => {
    handleCheckResetPasswordExpired();
  }, []);

  return (
    <div className="login login-bgr">
      {isLoadingExpired ? (
        <Loading />
      ) : (
        <>
          {isLoading && <Loading />}
          <div className="login__form">
            <div className="login__form-content">
              <h1 className="form-title mb-[24px]">パスワードの再設定</h1>
              <Form layout="vertical" autoComplete="off" form={antForm} onKeyPress={(e) => handleEnterSubmit(e)}>
                <div className="mb-[16px]">
                  <Form.Item
                    required
                    label="新しいパスワード："
                    name="password"
                    rules={[{ validator: (rule, value, callback) => validateNewPassword(rule, value, callback) }]}
                  >
                    <Input.Password
                      className="login__form__input"
                      onChange={(e) => handleChange(e.target.value)}
                      placeholder="新しいパスワードを入力してください"
                    />
                  </Form.Item>
                </div>

                <div className="mb-[16px]">
                  <Form.Item
                    required
                    label="新しいパスワードの再確認："
                    name="password_confirmation"
                    dependencies={['password']}
                    rules={[{ validator: (rule, value, callback) => validateConfirmPassword(rule, value, callback) }]}
                  >
                    <Input.Password
                      className="login__form__input"
                      onChange={(e) => handleChange(e.target.value)}
                      placeholder="新しいパスワードを再入力してください"
                    />
                  </Form.Item>
                </div>

                {messError && <p className="invalid-feedback whitespace-pre-line">{messError}</p>}

                <div className="footer-form flex">
                  <Button className="button-form mr-[16px]" onClick={() => handleGoBack()}>
                    キャンセル
                  </Button>
                  <Button
                    loading={isLoading}
                    className="button-form button-form-Submit"
                    onClick={() => handleSendEmailForgotPassword()}
                  >
                    <span></span>変更
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}

      <Modal
        title=""
        open={isOpenModalSuccess}
        width={400}
        className="modal-success"
        footer={false}
        closable={false}
        centered={true}
        afterClose={handleCloseModalSuccess}
      >
        <img src={success} alt="" className="mb-[16px] mx-auto" />
        <div className="text-[#2E2E2E] text-[20px] font-medium text-center mb-[8px]">パスワードが変更されました</div>
        <p className="text-[#2E2E2E] text-[14px] text-center mb-[44px]">
          パスワードは正常に変更されました。 新しいパスワードで再度ログインしてください。
        </p>
        <Button className="button-add w-full h-[48px]" onClick={() => handleCloseModalSuccess()}>
          ログイン画面へ戻る
        </Button>
      </Modal>

      <Modal
        title=""
        open={isOpenModalError}
        width={400}
        className="modal-success"
        footer={false}
        closable={false}
        centered={true}
        afterClose={handleCloseModalError}
      >
        <img src={error} alt="" className="mb-[16px] mx-auto" />
        <div className="text-[#2E2E2E] text-[20px] font-medium text-center mb-[8px]">エーラ</div>
        <p className="text-[#2E2E2E] text-[14px] text-center mb-[44px]">
          このURLリンクの有効期限が切れました。パスワードの忘れ画面へ戻してください。
        </p>
        <Button className="button-add w-full h-[48px]" onClick={() => handleCloseModalError()}>
          パスワードの忘れ画面へ戻る
        </Button>
      </Modal>
    </div>
  );
};

export default ResetPassword;
