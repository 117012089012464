import React from "react";

const RectangleSvg = ({
	shapeInfo,
	outline,
	drawOutline,
	handleClick,
	index,
}: any) => {
	if (drawOutline) {
		return (
			<rect
				id={index}
				onClick={handleClick}
				width={shapeInfo?.width}
				height={shapeInfo?.height}
				x={shapeInfo?.startX}
				y={shapeInfo?.startY}
				rx={shapeInfo?.borderRadiusX}
				ry={shapeInfo?.borderRadiusY}
				fill={outline?.paddingColor ?? "white"}
				stroke={shapeInfo?.stroke ?? "black"}
				strokeWidth={shapeInfo?.outlinePadding ?? "0.5"}
				strokeLinecap="round"
				strokeLinejoin="round"
				transform={
					shapeInfo?.transform
						? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})`
						: ""
				}
			/>
		);
	} else {
		return (
			<rect
				onClick={handleClick}
				width={shapeInfo?.width}
				height={shapeInfo?.height}
				x={shapeInfo?.startX}
				y={shapeInfo?.startY}
				rx={shapeInfo?.borderRadiusX}
				ry={shapeInfo?.borderRadiusY}
				fill={shapeInfo?.fillColor}
				transform={
					shapeInfo?.transform
						? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})`
						: ""
				}
			/>
		);
	}
};

export default RectangleSvg;
