import { useEffect, useState } from 'react';
import styles from 'src/components/features/superAdmin/EditorComponent/FinalStep/index.module.scss';
import {
  HEIGHT_STAGE,
  OUTLINE_PADDING_DEFAULT,
  OUTLINE_PADDING_TEXT_RANGE_SLIDER,
  RANGE_SLIDER_STEP_SIZE,
  SCALE_RANGE_SLIDER,
  SIZE_STAMP_RANGE_SLIDER,
  WIDTH_STAGE,
} from 'src/assets/dataUI';
import useItem from 'src/hook/useItem';
import { nanoid } from 'nanoid';
import {
  DEFAULT_EXTRA_STATE,
  calculateSvgSize,
  generateBlobFromBase64,
  getPathMinxMiny,
  revertCalculationBeforeTransform,
  revertPathStringByTransform,
} from '../../SvgMerge/utils';
import SvgMerge from '../../SvgMerge';
import stylesImage from '../index.module.scss';
import { Button, Slider } from 'antd';
import MenuBottom from 'src/components/common/menuBottom';
import { MenyStepChangeSize } from '../../SvgMerge/menu';
import { cropVisibleArea } from 'src/utils';
import { useRatioContext } from 'src/hook/useChangeRatio';

const Step3 = ({
  svgContent,
  handleSetFeature,
  setStep,
  newImage,
  setNewImage,
  imageBackUp,
  defaultStep,
  editMode,
  baseSvgContent,
}: any) => {
  const { createItem, updateItem } = useItem();
  const [currentTab, setCurrentTab] = useState(0);
  const [outlinePadding, setOutlinePadding] = useState(svgContent?.outlinePadding ?? 20);
  const [scaleRatio, setScaleRatio] = useState<any>(svgContent?.scale?.base ?? 1);

  const [scaleSlider, setScaleSlider] = useState<any>(svgContent?.scaleSlider ?? 1);
  const [shapeSizeData, setShapeSizeData] = useState<any>({});
  const {stateRatio} = useRatioContext()

  useEffect(() => {
    let shapeSize = calculateSvgSize({
      idToCheck: 'svg_outline_shape',
      baseShapeInfo: svgContent,
      isText: false,
    });

    shapeSize = revertCalculationBeforeTransform({
      transform: {
        x: 0,
        y: 0,
      },
      scale: {
        base: scaleRatio,
      },
      rotate: {
        a: 0,
        x: shapeSize.width / 2,
        y: shapeSize.height / 2,
        baseScale: scaleRatio,
      },
      outputSvgSize: shapeSize,
    });

    setShapeSizeData(shapeSize);
  }, [svgContent, scaleRatio]);

  useEffect(() => {
    if (editMode) {
      setScaleSlider(svgContent?.scaleSlider);
      setScaleRatio(svgContent?.scale?.base);
      setOutlinePadding(svgContent?.outlinePadding);
    }
  }, [svgContent]);

  useEffect(() => {
    if (defaultStep === 2 && editMode) {
      onSubmitFinal();
    }
  }, [defaultStep, editMode]);

  const onSubmitFinal = () => {
    let blobIn = generateBlobFromBase64({
      base64String: svgContent?.image_url,
    });

    var DOMURL = self.URL || self.webkitURL || self;
    var url: any = DOMURL.createObjectURL(blobIn);

    let shapeSize = calculateSvgSize({
      idToCheck: 'svg_outline_shape',
      baseShapeInfo: svgContent,
      isText: false,
    });

    shapeSize = revertCalculationBeforeTransform({
      transform: {
        x: 0,
        y: 0,
      },
      scale: {
        base: scaleRatio,
      },
      rotate: {
        a: 0,
        x: shapeSize.width / 2,
        y: shapeSize.height / 2,
        baseScale: scaleRatio,
      },
      outputSvgSize: shapeSize,
    });

    let moveObject = getPathMinxMiny({
      pathStringArray: svgContent?.children?.map((pathInfo: any) => {
        return pathInfo?.attributes?.d;
      }),
      transform: {
        x: shapeSize?.x - svgContent?.transform?.x,
        y: shapeSize?.y - svgContent?.transform?.y,
      },
    });

    let newPath = svgContent?.children?.map((pathInfo: any) => {
      return {
        ...pathInfo,
        attributes: {
          ...pathInfo?.attributes,
          d: svgContent?.original
            ? pathInfo?.attributes?.d
            : revertPathStringByTransform({
                pathString: pathInfo?.attributes?.d,
                transform: {
                  x: shapeSize?.x - svgContent?.transform?.x,
                  y: shapeSize?.y - svgContent?.transform?.y,
                },
                moveObject,
              }),
        },
      };
    });

    cropVisibleArea(
      svgContent?.image_url,
      (imageBase64Data: any, width: any, height: any, minX: any, minY: any, baseWidth: any, baseHeight: any) => {
        //will need to revert affected path here
        const newAttrs = {
          zIndex: 1,
          shapeData: [
            {
              type: 9,
              ...svgContent,
              attributes: {
                ...svgContent?.attributes,
                width: shapeSize.width,
                height: shapeSize.height,
                viewBox: `0 0 ${shapeSize.width} ${shapeSize.height}`,
              },
              width: shapeSize.width,
              height: shapeSize.height,
              image_url: imageBase64Data,
              imageBackUp: imageBackUp,
              children: newPath,
              outlinePadding: outlinePadding,
              transform: editMode
                ? baseSvgContent?.attrs?.shapeData[0]?.transform
                : {
                    x: (stateRatio?.ratioWidth - shapeSize.width * scaleRatio) / 2,
                    y: (stateRatio?.ratioHeight - shapeSize.height * scaleRatio) / 2,
                  },
              scale: {
                base: scaleRatio,
              },
              matrix: baseSvgContent?.attrs?.shapeData[0]?.matrix ?? false,
              scaleSlider,
              rotate: {
                a: editMode ? baseSvgContent?.attrs?.shapeData[0]?.rotate?.a : 0,
                x: shapeSize.width / 2,
                y: shapeSize.height / 2,
                baseScale: scaleRatio,
              },
              shapeSize: {
                x: 0,
                y: 0,
                width: shapeSize.width,
                height: shapeSize.height,
              },
              lastShapeSizeData: {
                width,
                height,
                minX,
                minY,
                baseWidth,
                baseHeight,
              },
            },
          ],
        };

        if (editMode) {
          updateItem(baseSvgContent?.id, (prevData) => ({
            ...newAttrs,
            zIndex: baseSvgContent?.attrs?.zIndex,
          }));
        } else {
          createItem({
            id: nanoid(),
            attrs: newAttrs,
          });
        }

        handleSetFeature();
      }
    );
  };

  return (
    <>
      {editMode && <div className="flex justify-center mb-3 text-[24px] text-[#515c6f]">カットライン調整</div>}

      <div className={styles.editor}>
        <div
          className={styles.stage}
          style={
            {
              width: stateRatio?.ratioWidth,
              height: stateRatio?.ratioHeight,
              '--bg-pos': `0px 0px`,
              '--bg-size': `0px 0px`,
            } as React.CSSProperties
          }
        >
          {svgContent && (
            <SvgMerge
              shapeData={[
                {
                  type: 9,
                  ...svgContent,
                  outlinePadding: outlinePadding,
                  transform: {
                    x: (stateRatio?.ratioWidth - svgContent.width * scaleRatio) / 2,
                    y: (stateRatio?.ratioHeight - svgContent.height * scaleRatio) / 2,
                  },
                  scale: {
                    base: scaleRatio,
                  },
                  rotate: {
                    a: 0,
                    x: 0,
                    y: 0,
                    baseScale: scaleRatio,
                  },
                  shapeSize: {
                    x: 0,
                    y: 0,
                    width: shapeSizeData.width,
                    height: shapeSizeData.height,
                  },
                },
              ]}
              outline={{
                showOutline: true,
              }}
            />
          )}
        </div>
      </div>

      <div style={{ display: 'none' }}>
        <SvgMerge
          shapeData={[
            {
              type: 9,
              ...svgContent,
              outlinePadding: OUTLINE_PADDING_DEFAULT,
              scale: 1,
            },
          ]}
          outline={{
            showOutline: false,
          }}
          extraState={DEFAULT_EXTRA_STATE}
          onDragDeleteEnd={(e: any) => console.log(e)}
          isSingleShowMode={true}
        />
      </div>
      <canvas id="canvas" width="800" height="400" style={{ display: 'none' }}></canvas>

      <div className="my-react-add-text flex justify-center mb-2">
        <div className="w-[500px]">
          <div className={stylesImage.content}>
            {currentTab === 1 && (
              <>
                <p className={stylesImage.scaleTitle}>拡大・縮小</p>

                <div className={stylesImage.scale}>
                  <Slider
                    tooltip={{ formatter: null }}
                    defaultValue={scaleSlider}
                    min={SCALE_RANGE_SLIDER - SIZE_STAMP_RANGE_SLIDER}
                    max={SCALE_RANGE_SLIDER + SIZE_STAMP_RANGE_SLIDER}
                    step={RANGE_SLIDER_STEP_SIZE}
                    onChange={(e) => {
                      setScaleSlider(Number(e));
                      setScaleRatio(Number(SCALE_RANGE_SLIDER + Number(e) / 10));
                    }}
                  />
                </div>
              </>
            )}
            {currentTab === 2 && (
              <>
                <p className={stylesImage.scaleTitle}>縁取りサイズ</p>

                <div className={stylesImage.scale}>
                  <Slider
                    tooltip={{ formatter: null }}
                    defaultValue={outlinePadding}
                    min={OUTLINE_PADDING_DEFAULT - OUTLINE_PADDING_TEXT_RANGE_SLIDER}
                    max={OUTLINE_PADDING_DEFAULT + OUTLINE_PADDING_TEXT_RANGE_SLIDER}
                    step={RANGE_SLIDER_STEP_SIZE}
                    onChange={(e) => {
                      setOutlinePadding(Number(e));
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <MenuBottom
        list={MenyStepChangeSize({
          onClickChangeWidth: () => setCurrentTab(1),
          onClickEnlarge: () => setCurrentTab(2),
        })}
      />

      <div className="flex justify-center gap-2 mt-3">
        <Button
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            if (editMode) {
              handleSetFeature();
            } else {
              setStep((prev: number) => prev - 1);
            }
          }}
        >
          {'<<前のステップへ'}
        </Button>
        <Button className="button-form button-form-Submit !w-auto" onClick={() => onSubmitFinal()}>
          OK
        </Button>
      </div>
    </>
  );
};

export default Step3;
