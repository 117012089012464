import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getToken, isFirstLogin } from 'src/utils/auth';
import Header from '../common/layouts/header';
import { unAuthRoutes } from 'src/utils/routes';
import ScrollToTop from '../common/layouts/ScrollToTop';
import { getLocalStorage } from 'src/utils/cookie';
import { ROLE_ADMIN } from 'src/assets/constants';

const Layout: React.FC = () => {
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const history = useNavigate();
  const location = useLocation();
  const isLogged = !unAuthRoutes.includes(location.pathname) && !!getToken();
  const roleUser = getLocalStorage('role');

  useEffect(() => {
    if (!getToken() && !unAuthRoutes.includes(location.pathname)) {
      setIsAuth(false);
      history('/login');
    }
    setIsAuth(true);
  }, [getToken(), history]);

  const onNavigateWhenLogin = (role?: number | string) => {
    if (Number(role) === ROLE_ADMIN) {
      history('/revenue');
    } else {
      history('/order');
    }
  };

  useEffect(() => {
    if (getToken()) {
      if (unAuthRoutes.includes(location.pathname)) {
        onNavigateWhenLogin(roleUser);
      }
    }
  }, [getToken(), location]);

  useEffect(() => {
    if (getToken()) {
      if (isFirstLogin()) {
        history('/change-password');
      }
    }
  }, [getToken(), isFirstLogin(), location.pathname]);

  if (!isAuth && !unAuthRoutes.includes(location.pathname)) return null;

  return (
    <>
      <ScrollToTop>
        <Header />
        <div
          className={`civil-content-page ${
            isLogged && location.pathname !== '/change-password' ? 'civil-content-auth' : ''
          }`}
        >
          <Suspense>
            <Outlet />
          </Suspense>
        </div>
      </ScrollToTop>
    </>
  );
};

export default Layout;
