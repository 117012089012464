import React, { createContext, useContext, useState, ReactNode } from 'react';

interface RatioState {
  ratioWidth: any,
  ratioHeight: any
}


interface RatioContextType {
  stateRatio: RatioState;
  setStateRatio: React.Dispatch<React.SetStateAction<RatioState>>;
}

const RatioContext = createContext<RatioContextType | undefined>(undefined);


export const RatioProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [stateRatio, setStateRatio] = useState<RatioState>({
    ratioWidth: null,
    ratioHeight: null
  });

  return (
    <RatioContext.Provider value={{ stateRatio, setStateRatio }}>
      {children}
    </RatioContext.Provider>
  );
};

export const useRatioContext: any = () => {
  const context = useContext(RatioContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
