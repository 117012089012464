const IconDownloadImage = ({ className }: any) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 50 50" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0965 35.9405C24.9071 36.019 24.7041 36.0593 24.4991 36.059C24.2941 36.0593 24.0898 36.0191 23.9004 35.9406C23.711 35.8621 23.5385 35.7464 23.3938 35.6013L15.0604 27.2679L15.0557 27.2633C14.9076 27.1189 14.7896 26.9465 14.7087 26.7562C14.6277 26.5658 14.5853 26.3613 14.584 26.1544C14.5827 25.9476 14.6225 25.7425 14.701 25.5512C14.7796 25.3598 14.8954 25.1859 15.0416 25.0397C15.1879 24.8934 15.3618 24.7776 15.5531 24.6991C15.7445 24.6205 15.9495 24.5808 16.1564 24.5821C16.3632 24.5834 16.5678 24.6257 16.7581 24.7067C16.9485 24.7877 17.1208 24.9057 17.2652 25.0538L24.4985 32.2872L31.7271 25.0585L31.7317 25.0538C31.8761 24.9057 32.0485 24.7877 32.2389 24.7067C32.4292 24.6257 32.6337 24.5834 32.8406 24.5821C33.0474 24.5808 33.2525 24.6205 33.4439 24.6991C33.6352 24.7776 33.8091 24.8934 33.9553 25.0397C34.1016 25.1859 34.2174 25.3598 34.2959 25.5512C34.3745 25.7425 34.4143 25.9476 34.413 26.1544C34.4116 26.3613 34.3693 26.5658 34.2883 26.7562C34.2073 26.9465 34.0894 27.1189 33.9413 27.2633L33.9366 27.2679L25.6027 35.6018C25.458 35.7469 25.2859 35.8621 25.0965 35.9405Z"
      fill="#2E2E2E"
    />
    <path
      d="M24.4785 35.4167C24.0641 35.4167 23.6667 35.1972 23.3737 34.8065C23.0806 34.4158 22.916 33.8859 22.916 33.3333V8.33333C22.916 7.7808 23.0806 7.25089 23.3737 6.86019C23.6667 6.46949 24.0641 6.25 24.4785 6.25C24.8929 6.25 25.2903 6.46949 25.5834 6.86019C25.8764 7.25089 26.041 7.7808 26.041 8.33333V33.3333C26.041 33.8859 25.8764 34.4158 25.5834 34.8065C25.2903 35.1972 24.8929 35.4167 24.4785 35.4167Z"
      fill="#2E2E2E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.0283 41.6715C39.9541 42.7457 38.4977 43.3499 36.9786 43.3516H11.9797C10.4606 43.3499 9.00422 42.7457 7.93004 41.6715C6.85594 40.5974 6.25174 39.1411 6.25 37.6221V35.5391C6.25 35.1247 6.41462 34.7272 6.70765 34.4342C7.00067 34.1412 7.3981 33.9766 7.8125 33.9766C8.2269 33.9766 8.62433 34.1412 8.91735 34.4342C9.21038 34.7272 9.375 35.1247 9.375 35.5391L9.375 37.623C9.37579 38.3133 9.65035 38.975 10.1384 39.4631C10.6265 39.9512 11.2889 40.2258 11.9792 40.2266L36.9799 40.2266C37.6701 40.2256 38.3317 39.951 38.8198 39.463C39.3078 38.975 39.5824 38.3126 39.5833 37.6224V35.5391C39.5833 35.1247 39.748 34.7272 40.041 34.4342C40.334 34.1412 40.7314 33.9766 41.1458 33.9766C41.5602 33.9766 41.9577 34.1412 42.2507 34.4342C42.5437 34.7272 42.7083 35.1247 42.7083 35.5391V37.6218C42.7067 39.1409 42.1025 40.5973 41.0283 41.6715Z"
      fill="#2E2E2E"
    />
  </svg>
);

export default IconDownloadImage;
