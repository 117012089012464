import { useQuery } from 'react-query';
import { api } from 'src/api/axios';

export const useListSizeAcrylicPlate = (acrylic_plate_id: any) =>
  useQuery(['listSizeAcrylicPlate', acrylic_plate_id], async () => {
    const res = await api.get(`/api/cms/template-design/list-acrylic-plate-size`, {
      params: {
        acrylic_plate_id,
      },
    });
    return res.data.data;
  });
