import { api } from 'src/api/axios';

export interface StaffEditType {
  name: string;
  email: string;
  phone_1: string;
  phone_2: string;
  phone_3: string;
  role: number | string;
}

export const useEditStaff = (params: StaffEditType, id: number | string) => api.post(`/api/cms/admin/${id}`, params);
