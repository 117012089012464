import React from "react";

// {
// type: 3,//cirle
// radius: 30,
// startX: 50,//this is center X
// startY: 50,//this is center Y
// fillColor: 'red'
// }
const CircleSvg = ({
	shapeInfo,
	outline,
	drawOutline,
	handleClick,
	index,
}: any) => {
	if (drawOutline) {
		return (
			<circle
				id={index}
				onClick={handleClick}
				r={shapeInfo?.radius}
				cx={shapeInfo?.startX ?? 50}
				cy={shapeInfo?.startY ?? 50}
				fill={outline?.paddingColor ?? "white"}
				stroke={shapeInfo?.stroke ?? "black"}
				strokeWidth={Number(shapeInfo?.outlinePadding) ?? 0.5}
				strokeLinecap="round"
				strokeLinejoin="round"
				transform={
					shapeInfo?.transform
						? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})`
						: ""
				}
			/>
		);
	} else {
		return (
			<circle
				onClick={handleClick}
				r={shapeInfo?.radius}
				cx={shapeInfo?.startX}
				cy={shapeInfo?.startY}
				fill={shapeInfo?.fillColor}
				transform={
					shapeInfo?.transform
						? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})`
						: ""
				}
			/>
		);
	}
};

export default CircleSvg
